export default [
    {
        path: '/settings/report-template',
        name: 'pageReportTemplateList',
        meta: { title: 'reportTemplate.listTitle' },
        component: () => import('@/pages/settings/report-template'),
    },
    {
        path: '/settings/report-template/create',
        name: 'pageReportTemplateCreate',
        meta: { title: 'reportTemplate.createFormTitle' },
        component: () => import('@/pages/settings/report-template/create'),
    },
    {
        path: '/settings/report-template/:id/edit',
        name: 'pageReportTemplateEdit',
        meta: { title: 'reportTemplate.editFormTitle' },
        component: () => import('@/pages/settings/report-template/id/edit'),
    },
]
