import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'offer',
    secondGroup: 'day',
    offers: null,
    countries: null,
    salesTypes: null,
    userGroups: null,
    operators: null,
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}
