export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  filter: {
    type: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    manager: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}
