<template>
  <tbody :class="tbodyClass">
    <ui-expand-header
      :headers="headers"
      :is-open="isOpen"
      :is-sortable="isSortable"
      :group="group"
      @onOpen="onOpenGroup"
      @onChangeSort="onChangeSort"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ item }">
        <slot
          v-if="$scopedSlots[`header.${header.value}`]"
          :name="`header.${header.value}`"
          :item="item"
          :value="item[header.value]"
          :header="header"
        >
          {{ item[header.value] }}
        </slot>

        <slot
          v-else
          :name="`item.${header.value}`"
          :item="item"
          :value="getValue(item, header.value)"
        >
          {{ getValue(item, header.value) }}
        </slot>
      </template>
    </ui-expand-header>

    <template v-if="isOpen">
      <ui-expand-content
        v-for="content in sortedItems"
        :key="content.uuid"
        :headers="filteredHeaders"
        :item="content"
      >
        <template v-for="header in filteredHeaders" v-slot:[`item.${header.value}`]="{ item }">
          <slot :name="`item.${header.value}`" :item="item" :value="getValue(item, header.value)">
            {{ getValue(item, header.value) }}
          </slot>
        </template>
      </ui-expand-content>
    </template>

    <slot name="append" />
  </tbody>
</template>
<script>
import UiExpandHeader from '@/components/ui/framework/general/UIExpandGroup/UIExpandHeader.vue'
import UiExpandContent from '@/components/ui/framework/general/UIExpandGroup/UIExpandContent.vue'
import { get, orderBy } from 'lodash'
import { randomString, splitArr } from '@/helpers/functions'
import { alphabet } from '@/constans/alphabet'
export default {
  name: 'UiExpandGroup',
  components: {
    UiExpandHeader,
    UiExpandContent,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    fixedColsCount: {
      type: Number,
      default: () => 0,
    },
    hasFixedCols: {
      type: Boolean,
      default: () => false,
    },
    closeOnDataChange: {
      type: Boolean,
      default: true,
    },
    valueMutator: {
      type: Function,
      default: () => null,
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      isSortable: false,
      filteredHeaders: [],
    }
  },
  computed: {
    innerSortKey() {
      return this.headers[0].value
    },
    sortedItems() {
      const orders = this.isSortable ? 'desc' : 'asc'
      const sortFunc = item => {
        return item[this.innerSortKey] && typeof item[this.innerSortKey] === 'string'
          ? item[this.innerSortKey].toLowerCase() : item[this.innerSortKey]
      }

      return orderBy(this.items, [sortFunc], [orders])
    },
    tbodyClass() {
      return randomString(alphabet, 8).join('')
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val && this.hasFixedCols) {
          setTimeout(() => {
            this.fixCols('.fixed-col.right.expanded-col')
          }, 0)
        }
      },
    },
    items: {
      handler() {
        if(this.closeOnDataChange){
          this.isOpen = false
        }
      },
      deep: true,
    },
    columnsToShow(data) {
      this.setHeaders(data)
      this.detachCols()
      this.$nextTick(() => {
        this.fixCols()
      })
    },
  },
  created() {
    this.setHeaders(this.columnsToShow)
  },
  mounted() {
    if (this.hasFixedCols) {
      this.fixCols()
    }
  },
  methods: {
    fixCols(classes = '.fixed-col.right') {
      const rows = splitArr(
        document.querySelectorAll(`.${this.tbodyClass} ${classes}`),
        this.fixedColsCount,
        true,
      )
      rows.forEach(row => {
        let prevElWidth = 0
        row.forEach((col, index) => {
          const rect = col.getBoundingClientRect()
          prevElWidth += rect.width

          if (index === 0) {
            col.style.right = '0px'
            return
          }

          let offset = rect.width
          let offsetNext = 0

          if (index > 0) offsetNext = Math.abs(offset - prevElWidth)
          col.style.right = offsetNext + 'px'
        })
      })
    },
    detachCols() {
      document.querySelectorAll(`.${this.tbodyClass} .fixed-col.right`).forEach(item => {
        item.style.right = null
      })
    },
    getValue(item, key) {
      const value = get(item, key)

      if (this.valueMutator) {
        return this.valueMutator({
          value,
          item,
          key,
        })
      }

      return value
    },
    onOpenGroup(isOpen) {
      this.isOpen = isOpen
    },
    onChangeSort(isSortable) {
      this.isSortable = isSortable
    },
    setHeaders(selectedHeaders = []) {
      if (!selectedHeaders.length) {
        this.filteredHeaders = [...this.headers]
        return
      }

      const colIDs = selectedHeaders.map(selectedItem => selectedItem.value)

      this.filteredHeaders = this.headers.filter(item => {
        return !colIDs.includes(item.value)
      })
    },
  },
}
</script>
