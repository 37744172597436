import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import moment from 'moment'
import { CURRENCIES } from '@/constans/currencies'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment()
        .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      salesType: {
        slug: {
          value: '',
          operator: 'in',
        },
      },
    },
    order: {
      createdAt: {
        value: '',
        operator: 'bwn',
      },
      approvedStatusDate: {
        value: '',
        operator: 'bwn',
      },
    },
    mainOperator: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    processingUser: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    region: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  search: '',
  otherFilters: {
    currency: CURRENCIES.EUR,
    groupBy: 'created_at',
    dates: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment()
        .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    mainOperator: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}