import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment()
        .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    order: {
      offer: {
        id: {
          value: '',
          operator: 'in',
        },
        salesType: {
          slug: {
            value: '',
            operator: 'in',
          },
        },
      },
      createdAt: {
        value: '',
        operator: 'bwn',
      },
      approvedStatusDate: {
        value: '',
        operator: 'bwn',
      },
    },
    user: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      countries: {
        id: {
          value: '',
          operator: 'in',
        },
        regions: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
    },
  },
  search: '',
  clientOptions: {
    filterPanelExpanded: false,
  },
}