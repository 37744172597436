import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OperatorEfficiency/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.OPERATOR_EFFICIENCY_FILTERS) || { ...defaultFilters },
  operatorEfficiencyList: [],
  operatorEfficiencyTotal: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  operatorEfficiencyList: state => state.operatorEfficiencyList,
  operatorEfficiencyTotal: state => state.operatorEfficiencyTotal,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOperatorEfficiencyList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_EFFICIENCY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.operatorEfficiency(preparedFilters.filter)
    data.rows = Object.values(data.rows)
    context.commit('setOperatorEfficiencyList', {
      data,
      pagination: mockPagination(data.rows, filters),
      filters,
    })

    return { operatorEfficiencyList: data.rows, operatorEfficiencyTotal: data.total }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OPERATOR_EFFICIENCY_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OPERATOR_EFFICIENCY_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OPERATOR_EFFICIENCY_FILTERS, filters)
    context.commit('setOperatorEfficiencyList', {
      data: {
        rows: context.getters.operatorEfficiencyList,
        total: context.getters.operatorEfficiencyTotal,
      },
      pagination: mockPagination(context.getters.operatorEfficiencyList, filters),
      filters,
    })
  },
}

const mutations = {
  setOperatorEfficiencyList(state, { data, filters, pagination }) {
    state.operatorEfficiencyList = data.rows
    state.operatorEfficiencyTotal = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
