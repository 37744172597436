import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/ReportTemplate/helpers'
import reportTemplateRepository from '@/repository/admin/reportTemplateRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, omitEmptyFilters, prepareFilters } from '@/helpers/filters'
import { formatDate } from '@/helpers/date'
import { DATE_DAY_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')
import ProfileModule from '@/store/modules/Profile.module'

const state = {
  list: [],
  filters: StorageService.get(storageKeys.REPORT_TEMPLATE_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.REPORT_TEMPLATE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await reportTemplateRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchReportTemplate(context, filter) {
    const filters = defaultFilters
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters ,
        ...filter
      },
      pagination: {
        limit: -1
      }
    })
    const { data } = await reportTemplateRepository.list(preparedFilters)
    const profile = ProfileModule.state.profile
    data.items = data.items.filter(i => {
      return i.users.some(u => u.id === profile.id) || i.roles.some(r => r.id === profile.role.id)
    })
    return { items: data.items, pagination: data.pagination }
  },
  async customReportExport(context, payload) {
    const { name, ...filters } = payload
    const appliedFilters = omitEmptyFilters(filters)
    const response = await reportTemplateRepository.exportReport(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const date = appliedFilters.dateFrom ? ` ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(filters.dateTo, DATE_DAY_FORMAT)}` : ''
    const filename = `${name}${date}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async loadItem(context, id) {
    const { data } = await reportTemplateRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return reportTemplateRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return reportTemplateRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return reportTemplateRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.REPORT_TEMPLATE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.REPORT_TEMPLATE_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
