import SuperRepository from '../superRepository'

export default new (class CallControllerRepository extends SuperRepository {
  baseUri() {
    return 'call_controller'
  }
  getRecordFile = async (id, params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/${id}/record_file`, params)
  report = async (data, params) =>
    this.httpClient().get(`${this.baseUri()}/report`, data, params)
  callStatisticReport = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/call_statistic`, data, params)
})()
