<template>
  <nav class="pagination">
    <ul class="pa-0">
      <li
        class="d-flex"
        :class="pagination.page === 1 ? 'disabled' : 'arrow-active'"
      >
        <a
          href="#"
          class="pa-0 d-flex justify-center align-center"
          @click.prevent="changePage(pagination.page - 1)"
        >
          <v-icon
            size="17"
            :color="pagination.page === 1 ? 'gray300' : 'blue600'"
          >
            mdi-chevron-left
          </v-icon>
        </a>
      </li>
      <li
        v-if="!pageNumbers.includes(1) && totalPages"
        :class="{ disabled: pagination.page === 1 }"
      >
        <a href="#" @click.prevent="changePage(1)">1</a>
      </li>
      <li
        v-if="!pageNumbers.includes(1) && !pageNumbers.includes(2) && totalPages"
        :class="{ disabled: pagination.page === 1 }"
      >
        <span class="px-0">...</span>
      </li>
      <li v-for="page in pageNumbers" :key="page" :class="{ active: page === pagination.page }">
        <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
      </li>

      <li
        v-if="!pageNumbers.includes(totalPages) && !pageNumbers.includes(totalPages - 1) && totalPages"
        :class="{ disabled: pagination.page === 1 }"
      >
        <span class="px-0">...</span>
      </li>
      <li
        v-if="!pageNumbers.includes(totalPages) && totalPages"
        :class="{ disabled: pagination.page === totalPages }"
      >
        <a href="#" @click.prevent="changePage(totalPages)">{{ totalPages }}</a>
      </li>
      <li
        class="d-flex"
        :class="pagination.page === totalPages || !totalPages ? 'disabled' : 'arrow-active'"
      >
        <a
          href="#"
          class="pa-0 d-flex justify-center align-center"
          @click.prevent="changePage(pagination.page + 1)"
        >
          <v-icon
            size="17"
            :color="pagination.page === totalPages || !totalPages ? 'gray300' : 'blue600'"
          >
            mdi-chevron-right
          </v-icon>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'UiPagination',
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    value: {
      type: Object,
      default: () => ({
        page: 1,
        limit: 20,
      }),
    },
  },
  data(){
    return {
      maxVisiblePagesDesktop: 3,
      maxVisiblePagesMobile: 1,
    }
  },
  computed: {
    pagination: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    totalPages() {
      return this.totalItems;
    },
    mobileView(){
      return this.$vuetify.breakpoint.smAndDown
    },
    maxVisiblePages(){
      return this.mobileView && ![1, 2, this.totalItems - 1, this.totalItems].includes(this.pagination.page)
        ? this.maxVisiblePagesMobile
        : this.maxVisiblePagesDesktop
    },
    pageNumbers() {
      const pages = [];
      const half = Math.floor(this.maxVisiblePages / 2);
      let start = Math.max(1, this.pagination.page - half);
      let end = Math.min(this.totalPages, start + this.maxVisiblePages - 1);
      if (end - start < this.maxVisiblePages - 1) {
        start = Math.max(1, end - this.maxVisiblePages + 1);
      }
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.pagination = { ...this.pagination, page };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination ul {
  list-style: none;
  display: flex;
  gap: 4px;
  li {
    display: inline;
    &.arrow-active {
      background: var(--v-primary-lighten6);
      border-radius: 4px;
      a {
        color: var(--v-primary-base);
      }
    }
    a, span {
      font-size: 12px;
      padding: 4px 7px;
      text-decoration: none;
      color: #8d8d8d;
    }
    a {
      cursor: pointer;
    }
    &.disabled a {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.pagination ul li.active a {
  background-color: #5757E2;
  color: white;
  border-color: #5757E2;
  border-radius: 4px;
}
</style>
