import moment from 'moment-timezone'
import { DATE_FORMAT, DATE_TIME_FORM_FULL_FORMAT, TIME_FORMAT } from '@/constans/date'
import ct from 'countries-and-timezones';
import ProfileModule from '@/store/modules/Profile.module'
import i18n from '@/plugins/vue-i18n'

export function getDateByTimeZone(data) {
  if (data) {
    return new Date().toLocaleString('en-GB', {
      timeZone: data,
      hour: '2-digit',
      minute: '2-digit',
    })
  } else {
    return ''
  }
}

export function getTimezoneByCountryCode(isProfile = false) {
  const isMultiCountry = ProfileModule.state.profile?.countries.length > 1
  const countryCode = ProfileModule.state.profile?.countries.length ?
    ProfileModule.state.profile.countries[0].countryCode : '';
  const savedTimezone = localStorage.getItem('timezone')
  if(!countryCode && isProfile && savedTimezone)
    return savedTimezone
  else if(!countryCode) {
    localStorage.setItem('timezone', 'Etc/GMT-2')
    return 'Etc/GMT-2'
  } else if(countryCode && isMultiCountry) {
    localStorage.setItem('timezone', 'Europe/Zurich')
    return 'Europe/Zurich'
  } else if(countryCode){
    const countryData = ct.getCountry(countryCode.toUpperCase());
    if (countryData && countryData.timezones.length > 0) {
      localStorage.setItem('timezone', countryData.timezones[countryCode === 'pt' ? 2 : 0])
      return countryData.timezones[countryCode === 'pt' ? 2 : 0];
    }
  } else {
    throw new Error('Часовой пояс для этой страны не найден');
  }
}

export function formatDateToCurrentUserCountry(date, format = DATE_FORMAT) {
  const timezone = getTimezoneByCountryCode()
  return date ? moment.utc(date).tz(timezone).format(format) : date
}

export function formatDate(date, format = DATE_FORMAT) {
  return date ? moment(new Date(date)).format(format) : date
}
export function formatDateNoTimeZone(date, format = DATE_FORMAT) {
  return date ? moment(new Date(date.split('+')[0])).format(format) : date
}

export function convertSecondsToFormatDate(date, format = TIME_FORMAT) {
  return moment().startOf('day').seconds(date).format(format)
}
export function convertSecondsToTimeFormat(seconds, isHours = false) {
  if(!seconds) return
  const prepSeconds = Math.round(seconds);
  const hours = Math.floor(prepSeconds / 3600);
  const minutes = Math.floor((prepSeconds % 3600) / 60);
  const sec = prepSeconds % 60;
  return `${String(hours).padStart(2, '0')}${
    isHours ? `${i18n.t('managerDashboard.dates.shortHours')} ` : ':'}${
    String(minutes).padStart(2, '0')}${
    isHours ? `${i18n.t('managerDashboard.dates.shortMinutes')} ` : ':'}${
    String(sec).padStart(2, '0')}${
    isHours ? `${i18n.t('managerDashboard.dates.shortSeconds')} ` : ''}`;
}

export function convertMinutesToFormatDate(dateMinutes, dateSeconds, format = TIME_FORMAT) {
  return moment().startOf('day').minutes(dateMinutes).seconds(dateSeconds).format(format)
}

export function diffDateSecondsToFormatDate(startDate, endDate, format = TIME_FORMAT) {
  const diff = moment(startDate).diff(moment(endDate), 'seconds')
  return moment().startOf('day').seconds(Math.abs(diff)).format(format)
}

export function formatRelativeTime(date) {
  const currentYear = moment().year()
  const dateFebruary = moment([currentYear, 1, 1]).format(DATE_TIME_FORM_FULL_FORMAT)
  const daysInMont = moment(dateFebruary).daysInMonth()
  const daysInYear = daysInMont === 28 ? 365 : 366

  moment.relativeTimeThreshold('d', daysInYear)
  return date ? moment(date).fromNow() : date
}
