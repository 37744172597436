export default [
  {
    path: '/call-centre/call-statistics',
    name: 'pageCallStatistics',
    meta: { title: 'page.pageCallStatistics', roles: ['CC_STATS_CALLS'] },
    component: () => import('@/pages/call-centre/call-statistics'),
  },
  {
    path: '/call-centre/call-statistics-old',
    name: 'pageCallStatisticsOld',
    meta: { title: 'page.pageCallStatisticsOld', roles: ['CC_STATS_CALLS'] },
    component: () => import('@/pages/call-centre/call-statistics-old'),
  },
]
