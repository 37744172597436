import TableOptions from '@/models/TableOptions'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OrderLogistics/helpers'
import { hasAppliedFilters, omitEmptyDeepFilters, omitEmptyFilters, prepareFilters } from '@/helpers/filters'
import orderRepository from '@/repository/admin/orderRepository'
import { DATE_DAY_FORMAT, DATE_FORMAT } from '@/constans/date'
import moment from 'moment'
import i18n from '@/plugins/vue-i18n'
import { formatDate } from '@/helpers/date'
import orderReportRepository from '@/repository/admin/orderReportRepository'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.ORDER_LOGISTICS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
  orderList: [],
  selectedColumns: null,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  orderList: state => state.orderList,
  hasAppliedFilters: state => {
    return hasAppliedFilters(state.filters)
  },
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  selectedColumns: state => state.selectedColumns,
}

const actions = {
  async loadOrderList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_LOGISTICS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilters = omitEmptyDeepFilters(filters.custom_filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        status: {
          value: 'shipped|delivered|no_ransom|approved|packaging|paid|canceled',
          operator: 'in',
        },
        ...appliedFilters,
      },
      cfFilter: appliedCfFilters,
      custom_filter: appliedCustomFilters,
    })

    const { data } = await orderRepository.list(preparedFilters, {
      headers: {
        'additional-groups': 'order_logistic_group',
      },
    })

    context.commit('setOrderList', { data, filters })

    return { orderList: data.items, pagination: data.pagination }
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilters = omitEmptyDeepFilters(filters.custom_filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        status: {
          value: 'shipped|delivered|no_ransom|approved|packaging|paid',
          operator: 'in',
        },
        ...appliedFilters,
      },
      cfFilter: appliedCfFilters,
      custom_filter: appliedCustomFilters,
    })
    const response = await orderRepository.export(preparedFilters)
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Orders.xlsx'
    saveAs(blob, filename)
    return response
  },
  async logisticSynchronization(context, formData) {
    return await orderRepository.logisticSynchronization(formData)
  },
  async exportDistrict(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilters = omitEmptyDeepFilters(filters.custom_filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      custom_filter: appliedCustomFilters,
      pagination: {
        limit: -1,
      },
    })
    const dateArray = appliedFilters.createdAt.value.split('|')
    const dateFrom = moment(dateArray[0]).format(DATE_FORMAT)
    const dateTo = moment(dateArray[1]).format(DATE_FORMAT)
    const response = await orderRepository.exportDistrict(preparedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `Области ${dateFrom} - ${dateTo}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async orderReport(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await orderReportRepository.orderReportLogistic(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.orders')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(payload.dateTo, DATE_DAY_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async orderReportCod(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await orderReportRepository.orderReportCod(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('exportReports.reportCod')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(payload.dateTo, DATE_DAY_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_LOGISTICS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions: {
        ...getters.filters.clientOptions,
        ...clientOptions,
      },
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_LOGISTICS_FILTERS, updatedFilters)
  },
  setSelectedColumns({ commit }, payload) {
    const filters = StorageService.get(storageKeys.ORDER_LOGISTICS_FILTERS)
    filters.clientOptions.selectedColumns = payload
    commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_LOGISTICS_FILTERS, filters)

    commit('setSelectedColumns', payload)
  },
}

const mutations = {
  setOrderList(state, { data, filters }) {
    state.orderList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setSelectedColumns(state, payload) {
    state.selectedColumns = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
