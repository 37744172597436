import SuperRepository from '../superRepository'

export default new (class logRepo extends SuperRepository {
  baseUri() {
    return 'history_data'
  }
  getOrdersLog = async data =>
    this.httpClient().put(`${this.baseUri()}`, data)
  export = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/export`, data, params)
})()
