export const ORDER_STATUS = {
  ACCEPTED: 'accepted',
  CALLBACK: 'callback',
  APPROVED: 'approved',
  CANCELED: 'canceled',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  NO_RANSOM: 'no_ransom',
  PACKAGING: 'packaging',
  LOST_PACKAGE: 'lost_package',
  // old statuses
  PROCESSING: 'processing',
  INCORRECT: 'incorrect',
  SUSPICIOUS: 'suspicious',
  AWAITING_RANSOM: 'awaiting_ransom',
  TRASH: 'trash',
  PAID: 'paid',
  PARTIALLY_PAID: 'partially_paid',
}

export const ORDER_COLOR_BY_STATUS = {
  ACCEPTED: 'gray300',
  CALLBACK: 'warning',
  APPROVED: 'success',
  CANCELED: 'error',
  SHIPPED: 'success',
  DELIVERED: 'success',
  NO_RANSOM: 'error',
  PACKAGING: 'success',
  LOST_PACKAGE: 'error',
  // old statuses
  PROCESSING: 'gray300',
  INCORRECT: 'error',
  SUSPICIOUS: 'warning',
  AWAITING_RANSOM: 'success',
  TRASH: 'trashStatus',
  PAID: 'success',
  PARTIALLY_PAID: 'success',
}
