export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {

    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
