import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/CallController/helpers'
import callControllerRepository from '@/repository/admin/callControllerRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.CALL_CONTROLLER) || { ...defaultFilters },
  callControllerList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  callControllerList: state => state.callControllerList,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCallControllerList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_CONTROLLER, filters)
    context.commit('setLoadingData', true)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await callControllerRepository.list(preparedFilters)

    context.commit('setCallControllerList', { data, filters })
    context.commit('setLoadingData', false)

    return {
      callControllerList: data.items,
      pagination: data.pagination,
    }
  },
  async loadCallControllerListForKln(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await callControllerRepository.list(preparedFilters)

    return data.items
  },
  async loadRecordFile(context, id) {
    const { data } = await callControllerRepository.getRecordFile(id)
    const blob = new Blob([data], { type: 'audio/mpeg' })

    return URL.createObjectURL(blob)
  },
  async downloadRecordFile(context, id) {
    const { data } = await callControllerRepository.getRecordFile(id)
    const blob = new Blob([data], { type: 'audio/mpeg' })
    const filename = `${id}.mp3`
    saveAs(blob, filename)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CALL_CONTROLLER)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CALL_CONTROLLER, updatedFilters)
  },
}

const mutations = {
  setCallControllerList(state, { data, filters }) {
    state.callControllerList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
