import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/CallAnalytics/helpers'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import callControllerRepository from '@/repository/admin/callControllerRepository'


const state = {
  filters: StorageService.get(storageKeys.CALL_ANALYTICS_FILTERS) || { ...defaultFilters },
  callAnalyticsList: [],
  reportOrders: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  reportOrders: state => state.reportOrders,
  callAnalyticsList: state => state.callAnalyticsList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCallAnalyticsList(context, payload) {
    const { currency, ...filters } = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_ANALYTICS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
      currency,
      otherFilters: {}
    })
    const { data } = await callControllerRepository.report(preparedFilters)
    const ordersArray = Object.values(data).reduce((acc, item) => {
      Object.values(item.items).forEach(i => {
        acc.push(...i.orders)
      })
      return acc
    }, [])
    const orders = [...new Set(ordersArray)];
    context.commit('setCallAnalyticsList', {
      data,
      pagination: mockPagination(Object.values(data), filters),
      filters,
      orders,
    })

    return { callAnalyticsList: data, orders }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CALL_ANALYTICS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CALL_ANALYTICS_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_ANALYTICS_FILTERS, filters)
    context.commit('setCallAnalyticsList', {
      data: context.getters.callAnalyticsList,
      pagination: mockPagination(context.getters.callAnalyticsList, filters),
      filters,
    })
  },
  updateStorageFilters(context, filters){
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_ANALYTICS_FILTERS, filters)
  }
}

const mutations = {
  setCallAnalyticsList(state, { data, filters, pagination, orders }) {
    state.callAnalyticsList = Object.entries(data)
    state.reportOrders = [...orders]
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
