export const locale = {
  // __GENERATED_TRANSLATIONS__
  callAnalytics: {
    callCentre: {
      title: 'Колл-центр',
      user: 'Пользователь',
      ordersCount: 'К-во заявок',
      took: 'Взято',
      callback: 'Колбэк',
      canceled: 'Отмена',
      trash: 'Треш',
      approve: 'Апрув',
      approvePercent: '% апрува',
      quality: 'Качество',
      upsell: 'Апсейл',
      cross: 'Кросс',
      gift: 'Подарок',
      coupon: 'Купон',
    },
    warehouse: {
      title: 'Склад',
      packaging: 'Упаковка',
    },
    logistic: {
      title: 'Логистика',
      shipped: 'В дороге',
      delivered: 'Доставлено',
      ransom: 'Возврат',
    },
    finance: {
      title: 'Финансы',
      averageCheck: 'Ср. чек',
    },
    table: {
      dateTime: 'Дата и время',
      offer: 'Оффер',
      orderId: '№ заявки',
      user: 'Пользователь',
      status: 'Статус',
      statusReason: 'Причина статуса',
      nextCall: 'Следующий звонок',
      duration: 'Длительность',
    }
  },
  orderLogs: {
    table: {
      date: 'Дата и время',
      user: 'Пользователь',
      action: 'Действие',
      section: 'Раздел',
      parameter: 'Параметр',
      was: 'Было',
      became: 'Стало',
      status: 'Статус',
    }
  },
   reportTemplate: {
     listTitle: 'Конструктор отчетов',
     editFormTitle: 'Редактирование отчета',
     createFormTitle: 'Создание отчета',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     table: {
       id: 'ID',
       name: 'Название',
       actions: 'Действия',
     },
     field: {
       name: 'Название',
       roles: 'Должности',
       reportsList: 'Список отчетов',
       users: 'Пользователи',
       filters: 'Фильтры',
       addToLogistic: 'Добавить на список заявок логистики',
       columns: 'Колонки',
     },
  },
   invoiceTranslations: {
     listTitle: 'Переводы инвойсов',
     editFormTitle: 'Редактирование перевода',
     createFormTitle: 'Создание перевода',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     table: {
       id: 'ID',
       country: 'Страна',
       actions: 'Действия',
     },
     field: {
       supplier: 'Поставщик',
       recipient: 'Получатель',
       approveDate: 'Дата апрува',
       invoiceDate: 'Дата инвойса',
       paymentType: 'Тип оплаты',
       productName: 'Название продукта',
       secondColumn: 'Вторая колонка',
       clearPrice: 'Чистая цена',
       quantity: 'Количество',
       orderNumber: 'Номер заявки',
       priceWithoutTax: 'Цена без налога',
       percentColumn: 'Колонка процент',
       taxPercent: 'Процент налога',
       totalPrice: 'Общая цена',
       baseTax: 'Базовый налог',
       priceWithTax: 'Цена с налогом',
       delivery: 'Доставка',
       total: 'Итого',
       totalWithTax: 'Итого с налогом',
       wordPrice: 'Цена словами',
       country: 'Страна',
     },
  },
   
   invoiceConstructor: {
     listTitle: 'Конструктор инвойсов',
     editFormTitle: 'Редактирование инвойса',
     createFormTitle: 'Создание инвойса',
     formFieldsBlockTitle: 'Основное',
     formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
     table: {
       id: 'ID',
       name: 'Название',
       country: 'Страна',
       warehouses: 'Склады',
       actions: 'Действия',
     },
     field: {
       name: 'Название',
       country: 'Страна',
       warehouses: 'Склады',
       header: 'Заголовок',
       supplier: 'Поставщик',
       recipient: 'Получатель',
       title: 'Подзаголовок',
       subtitle: 'Заголовок для подзаголовка',
       taxPercentage: 'Размер налога',
       thankYouText: '"Thank you" текст',
       phones: 'Номер телефона',
       additionalInfo: 'Дополнительная информация',
       locale: 'Язык инвойса',
       generateBarcode: 'Сгенерировать штрих-код',
       includeShippingCost: 'Включить стоимость доставки',
       showOrderNumber: 'Показать номер заявки',
       showDeliveryPrice: 'Показать стоимость доставки',
     },
  },
  operatorOrderTimeReport: {
    listTitle: 'Активность операторов',
    editFormTitle: 'Редактирование подстатуса',
    createFormTitle: 'Создание подстатуса',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    table: {
      id: 'ID',
      actions: 'Действия',
    },
    field: {
      name: 'Название*',
      active: 'Статус',
    },
  },

  currencyRate: {
    listTitle: 'Курсы валют',
    history: 'История курсов валют',
    historyCreate: 'Создание курса валют',
    historyUpdate: 'Редактирование курса валют',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    table: {
      id: 'ID',
      currency: 'Код валюты',
      rate: 'Значение курса',
      date: 'Дата действия',
      createdBy: 'Кто создал',
      actions: 'Действия',
    },
    field: {
      currency: 'Код валюты',
      rate: 'Курс валюты',
      date: 'Дата',
    },
  },
  callCenterWebs: {
    listTitle: 'Вэбы',
    editFormTitle: 'Редактирование вэба',
    createFormTitle: 'Создание вэба',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    table: {
      id: 'ID',
      outerId: 'ID Внешний',
      name: 'Название',
      actions: 'Действия',
    },
    field: {
      outerId: 'ID Внешний',
      name: 'Название',
    },
  },
  filterPreset: {
    create: 'Создать новый пресет',
    save: 'Сохранить новый пресет',
    noPresets: 'У вас пока нет пресетов',
    noPresetsFound: 'Пресеты не найдены',
    searchPlaceholder: 'Поиск по пресетам...',
    success: 'Пресет "{title}" успешно сохранен',
  },
  productTypes: {
    main: 'Основной',
    cross_product: 'Кросс товар',
    gift: 'Подарок',
    coupon: 'Купон',
  },
  messagesStatistic: {
    pageTitle: 'Статистика по SMS',
    listTitle: 'Статистика по отправленным SMS',
    id: 'ID',
    date: 'Дата и время',
    orderId: 'ID заказа',
    offer: 'Оффер',
    number: 'Номер',
    sms: 'SMS',
    user: 'Пользователь',
  },
  operatorStatuses: {
    listTitle: 'Статусы операторов',
    editFormTitle: 'Редактирование подстатуса',
    createFormTitle: 'Создание подстатуса',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    resetOperatorStatus: 'Сбросить статус',
    table: {
      name: 'Оператор',
      groups: 'Группы операторов',
      country: 'Страна',
      status: 'Статус',
      actions: 'Действия',
    },
    statuses: {
      offline: 'Оффлайн',
      not_available: 'Недоступен',
      wait: 'Ожидание',
      call: 'Звонок',
      application_processing: 'Обработка заявки',
      personal_break: 'Перерыв',
      technical_break: 'Тех. перерыв',
      manual_call: 'Звонки вручную',
      education: 'Обучение',
      kln: 'КЛН',
      consultation: 'Консультация',
      order_processing: 'Обработка заявки',
    },
  },
  callbackReasons: {
    busy: 'Абонент занят',
    no_answer: 'Номер не отвечает',
    disconnented: 'Обрыв связи',
    no_free_operators: 'Нет свободных операторов',
    technical_error: 'Техническая ошибка',
    operator_did_not_answer: 'Оператор не ответил',
    ask_callback: 'Клиент просит перезвонить',
    send_later: 'Отправить позже',
    specify_info: 'Уточнить информацию',
    number_not_available: 'Номер недоступен',
  },
  orderSubStatuses: {
    listTitle: 'Подстатусы',
    editFormTitle: 'Редактирование подстатуса',
    createFormTitle: 'Создание подстатуса',
    formFieldsBlockTitle: 'Основное',
    countriesSms: 'Страны и SMS шаблоны',
    autoSendSms: 'Авт. отправка SMS',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    hasTracker: 'Наличие трекера',
    table: {
      id: 'ID',
      name: 'Название',
      cpaText: 'Текст для CPA',
      statusDS: 'Статус СД',
      smsTemplates: 'СМС шаблоны',
      status: 'Применима к статусам',
      isOperator: 'Указывает оператор',
      actions: 'Действия',
    },
    field: {
      name: 'Название',
      status: 'Применима к статусам',
      cpaText: 'Текст для CPA',
      sdStatus: 'Статус СД',
      isOperator: 'Причину указывает оператор',
    },
  },
  writeOffHistory: {
    listTitle: 'Списание товаров',
    editFormTitle: 'Редактирование списания товаров',
    createFormTitle: 'Создание списания товаров',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    table: {
      id: 'ID',
      title: '№ заказа',
      product: 'Продукт',
      amount: 'Кол-во',
      country: 'Страна',
      warehouse: 'Склад',
      date: 'Дата списания',
      actions: 'Действия',
    },
    field: {
      name: 'Название*',
      active: 'Статус',
    },
  },

  postalCodes: {
    listTitle: 'Почтовые коды',
    editFormTitle: 'Редактирование почтового кода',
    createFormTitle: 'Создание почтового кода',
    importFormTitle: 'Загрузить из файла',
    formFieldsBlockTitle: 'Основное',
    formCreateUpdateSubmitErrorMessage: 'Ошибка при отправке запроса',
    table: {
      id: 'ID',
      title: 'Название',
      country: 'Страна',
      code: 'Почтовый индекс',
      region: 'Область',
      deliveryService: 'Служба доставки',
      warehouse: 'Склад',
      errors: 'Информация',
      actions: 'Действия',
    },
    field: {
      name: 'Название*',
      active: 'Статус',
    },
  },
  filters: {
    all: 'Все',
    activity: 'Активность',
    operator: 'Тип: Оператор',
    terminalPayment: 'Наличие терминала',
    country: 'Страна',
    countries: 'Страны',
    countryReq: 'Страна*',
    countriesReq: 'Страны*',
    region: 'Область',
    regions: 'Области',
    regionReq: 'Область*',
    regionsReq: 'Области*',
    deliveryService: 'Служба доставки',
    deliveryServices: 'Службы доставки',
    deliveryServiceReq: 'Служба доставки*',
    deliveryServicesReq: 'Службы доставки*',
    warehouse: 'Склад',
    warehouses: 'Склады',
    warehouseReq: 'Склад*',
    warehousesReq: 'Склады*',
  },
  settings: {
    saveSuccess: 'Данные успешно обновлены',
    uploadSuccess: 'Данные успешно загружены!',
  },
  // crm-sky
  base: {
    noPageToNavigate: 'Нет страницы для перехода',
    leaveWithoutSave: 'Выйти без сохранения',
    preset: 'Пресеты',
    period: 'Период',
    importData: 'Импортировать данные',
    listStatusActive: 'Активно',
    listStatusInactive: 'Деактивировано',
    hideCols: 'Скрыть колонки',
    save: 'Сохранить',
    cancel: 'Отменить',
    remove: 'Удалить',
    delete: 'Убрать',
    create: 'Создать',
    confirm: 'Подтвердить',
    loadFromFile: 'Загрузить из файла',
    areYouSureToDelete: 'Вы действительно хотите удалить?',
    areYouSureToDeactivate: 'Подтвердите деактивацию',
    areYouSureToActivate: 'Подтвердите активацию',
    details: 'Подробнее',
    logs: 'Логи',
    yes: 'Да',
    no: 'Нет',
    add: 'Добавить',
    edit: 'Редактировать',
    creating: 'Создание',
    editing: 'Редактирование',
    time: 'Время',
    back: 'Назад',
    filters: 'Фильтры',
    apply: 'Применить',
    selectAll: 'Выбрать все',
    clearAll: 'Очистить все',
    deactivate: 'Деактивировать',
    activate: 'Активировать',
    accepted: 'Новый заказ',
    processing: 'В обработке',
    incorrect: 'Неправильный',
    approved: 'Одобренный',
    rejected: 'Отклоненный',
    search: 'Поиск',
    noData: 'Нет данных',
    all: 'Все',
    allCount: 'Все ({count})',
    onlyChosen: 'Только выбранные',
    attention: 'Внимание',
    fill: 'Заполнить',
    validationError: 'Ошибка валидации',
    validationErrorServer: 'Ошибка сервера',
    deliveryServiceAndWarehouseCitiesValidationError:
      'Заполните либо удалите набор полей в блоке "Служба доставки и склад"',
    noMoreItems: 'Весь список загружен',
    noElementsFound: 'Не найдено элементов',
    import: 'Импорт',
    resetFilters: 'Сбросить фильтры',
    createdAt: 'Дата создания',
    createdBy: 'Кто создал',
    actions: 'Действия',
    city: 'Город',
    district: 'Район',
    region: 'Область',
    country: 'Страна',
    today: 'Сегодня',
    yesterday: 'Вчера',
    currentMonth: 'Текущий месяц',
    noDataAvailable: 'Данные недоступны',
    startTypingText: 'Начните вводить текст',
    change: 'Изменить',
    day: 'Дней',
    hour: 'Часов',
    minute: 'Минут',
    minutes: 'минут',
    seconds: 'секунд',
    toTheBeginning: 'В начало',
    warning: 'Предупреждение',
    choosePeriod: 'Выбрать период',
    filtersActive: 'Активные',
    filtersDeactivated: 'Деактивированные',
    filtersAll: 'Все',
    selectFields: 'Выбрать колонки',
    viewAll: 'Посмотреть все',
    open: 'Свернуть',
    expand: 'Развернуть',
    close: 'Закрыть',
    reset: 'Сбросить',
    operator: 'Оператор',
    notOperator: 'Не оператор',
    export: 'Экспорт',
    exportXls: 'Экспорт',
    images: 'Изображение',
    product: 'Продукт',
    selected: 'Выбрано',
    items: 'элементов',
    onPage: 'На странице',
    paginationOnPage: 'На странице',
    paginationFrom: 'из',
    history: 'История',
    field: 'Поле',
    rub: 'Рубль',
    uah: 'Гривна',
    mdl: 'Молдавский лей',
    success: 'Успешно',
    comments: 'Комментарии',
    choose: 'Выбрать',
    value: 'Значение',
    role: 'Должность',
    group: 'Группа',
    month: 'Месяц',
    niche: 'Ниша',
    total: 'Всего',
    from: 'из',
    mobileView: 'Для корректной работы системы воспользуйтесь десктопной версией',
    goToMain: 'На главную',
    showNumber: 'Показать номер',
    failedToLoad: 'Не удалось загрузить',
    failedToLoadDescription: 'Ошибка во время загрузки страницы. Попробуйте перезагрузить страницу',
    limitText: 'и еще {count}',
    addSelectedOptions: 'Добавить выбранные опции',
    removeSelectedOptions: 'Исключить выбранные опции',
    ok: 'Хорошо',
    selectDate: 'Выбрать дату',
    selectMonth: 'Выбрать месяц',
    presets: {
      today: 'Сегодня',
      todayAndYesterday: 'Сегодня и вчера',
      yesterday: 'Вчера',
      nextWeek: 'Следующая неделя',
      currentWeek: 'Текущая неделя',
      previousWeek: 'Прошлая неделя',
      currentMonth: 'Текущий месяц',
      previousMonth: 'Прошлый месяц',
      currentYear: 'Текущий год',
    },
    beWaiting: 'Ожидайте',
    dataLoading: 'Данные обрабатываются',
    copyPrefix: 'Копия',
    copy: 'Копировать',
    language: 'Язык',
    exit: 'Выход',
  },
  call: {
    order: 'Заявка',
    offer: 'Оффер',
    call: 'Звонок',
    answer: 'Ответить',
    reject: 'Завершить',
    voice: 'Голос',
    callbackList: 'Список колбеков',
    callRecord: 'Запись звонка',
    recordError:'Запись звонка больше недоступна, так как она была удалена с сервера.',
    wrongDate: 'Неправильная дата',
    aboveMaxAge: 'Неправильная дата (пользователю больше 120 лет)',
    datePlaceholder: 'дд/мм/гггг',
  },
  dashboard: {
    main: 'Дашборд',
    operator: 'Дашборд оператора',
    applications: 'Заявки',
    operatorOrders: 'Заявки оператора',
    suspiciousOrders: 'Подозрительные заявки',
    loadFromFile: 'Загрузить из файла',
    calls: 'Звонки',
    controlList: 'Оценки КЛН',
    klnOperator: 'КЛН оператора',
    ccStatisticsDashboard: 'Загрузка КЦ',
    questioning: 'Анкетирование',
    normsAndGrades: 'Нормы и грейды',
    normsAndGradesSub: {
      operatorGrades: 'Грейды операторов',
      offerNorms: 'Нормы по офферам',
    },
    missedCalls: 'Пропущенные вызовы',
    tickets: 'Тикеты',
    myTickets: 'Мои тикеты',
    potentialTrash: 'Потенциальный треш',
    potentialTrashSub: {
      notProcessed: 'Не обработанные',
      processed: 'Обработанные',
      disputable: 'Спорные',
    },
    callCampaigns: 'Кампании прозвона',
    smsCampaigns: 'SMS кампании',
    repeatedConfirm: 'Повторные подтверждения',
    stats: 'Статистика',
    statsSub: {
      operatorsTimeTracking: 'Учет времени операторов',
      incorrectStats: 'Статистика некорректных',
      fraudSpam: 'Фрод/Спам',
      statsCC: 'Статистика КЦ',
      statsRepeat: 'Статистика ПП',
      checkDistribution: 'Распределение чеков',
      statsKLN: 'Статистика КЛН',
      approveSpeed: 'Скорость апрува',
      statsRejected: 'Статистика отклоненных',
      statsCancelled: 'Статистика отмененных',
      statsCall: 'Статистика по звонкам',
      statsCallOld: 'Статистика по звонкам (old)',
      dostavimReport: 'Отчет почта-dostavim',
    },
    logs: 'Логи',
    logsSub: {
      loginLogs: 'Лог логинов',
      orderLogs: 'Лог заявок',
      watchNumberLogs: 'Просмотр телефонов/заявок',
      accountsLog: 'Логи аккаунтов',
    },
    reportsExport: 'Экспорт отчетов',
    reportExport: 'Экспорт отчета',
    settings: 'Настройки',
    courierDelivery: 'Курьерская доставка',
    pointOfIssue: 'Пункт выдачи',
    post: 'Аналитика служб доставки',
    troubleDelivery: 'Проблемная доставка',
    deliveryServiceAnalytic: 'Аналитика служб доставки',
    financialReport: 'Финансовый отчет',
    dynamic: 'Динамика',
    averageBills: 'Средние чеки',
    ransomByDay: 'Выкуп по дням',
    dynamicRansomByDay: 'Динамика выкупа по дням',
    hungRansom: 'Зависший выкуп',
    buybackDynamics: 'Динамика выкупа по дням',
    effOfAdditProjects: 'Эф. доп. проектов',
    finDepLog: 'Лог фин. отдела',
    ccPrepayment: 'Зарплаты КЦ',
    ccPrice: 'Стоимость КЦ',
    ccPriceSub: {
      operatorSalary: 'ЗП операторов',
      prepaidOper1: 'Аванс 1 операторов',
      prepaidOper2: 'Аванс 2 операторов',
      operatorVacations: 'Отпуски операторов',
      teamLeadSalary: 'ЗП тимлидов',
      prepaidTeamLead1: 'Аванс 1 тимлидов',
      prepaidTeamLead2: 'Аванс 2 тимлидов',
    },
    notificationsPrice: 'Стоимость уведомлений',
    logisticPrice: 'Стоимость логистики',
    logisticSalary: 'Зарплаты логистика',
    logisticPriceSub: {
      rpSalary: 'ЗП РП',
      officeSalary: 'ЗП офис',
      courierSalary: 'ЗП курьеры',
      hubHelpersSalary: 'ЗП помощники хаба',
    },
    comissionsAndTaxes: 'Прочие комиссии',
    websPayouts: 'Выплаты вебам',
    legalPersonsStats: 'Статистика по юр. лицам',
    penalty: 'Штрафы и премии оп.',
    operatorSchedule: 'График работы КЦ',
    smsChain: 'Цепочки уведомлений',
    autoCall: 'Автообзвон',
  },
  uploadArea: {
    maxSize: 'Максимальный размер файла {maxSize} MB',
    moveYourFileOr: 'Перетащите ваш файл или',
    upload: 'загрузите',
    limit: 'Максимальное количество файлов - {limit} которое можно загрузить',
    invalidType: 'Неверный формат файла для загрузки',
  },
  lang: {
    ru: 'Русский',
    en: 'Английский',
  },
  authLogin: {
    username: 'Email',
    password: 'Пароль',
    loginIn: 'Войти',
    codeConfirm: 'Продолжить',
    invalidCredentials: 'Неправильный или устаревший код!',
    forgetPassword: 'Забыли пароль?',
    welcome: 'Добро пожаловать!',
    twoFactorTitle: 'Настройка двухфакторной аутентификации',
    twoFactorDescription:
      'При помощи мобильного приложения Google Authenticator отсканируйте этот QR-код. В приложении появится шестизначный ключ, который необходимо ввести в поле ниже.',
    qrCode: 'Код из приложения',
    googleCode: 'Код Google Authenticator',
    step: 'Шаг',
    step1SubTitle: 'Загрузите мобильное приложение',
    step2SubTitle: 'Отсканируйте этот QR-код',
    step3SubTitle: 'Введите код аутентификации',
    step1SubDescription:
      'Скачайте и установите Google Authenticator (Android - IOS) на телефон или планшет',
    step2SubDescription:
      'Откройте приложениe, кликните на «+» в верхнем правом углу и отсканируйте код с помощью камеры',
    step3SubDescription:
      'После сканирования QR-кода, введите шестизначный код, сгенерированный приложением',
    errorComparePassword: 'Пароль не соответствует всем требованиям',
    usedPassword: 'Пароль использовался ранее в системе',
    errorPassword: 'Неправильный пароль',
    errorLogin: 'Неправильный email',
  },
  authRestore: {
    email: 'Email',
    forgetPassword: 'Восстановление пароля',
    authRestoreDesc:
      'Введите email своего аккаунта. На него будет отправлена ссылка для восстановления/смены пароля:',
    send: 'Отправить',
    checkYourEmail: 'Проверьте почту',
    sentDesc: 'Мы отправили ссылку для восстановления пароля на',
    isNotFounded: 'Email не соответствует ни одной учетной записи. Попробуйте еще раз!',
    wrongEmail: 'Неправильный email?',
    reEnter: 'Ввести заново!',
  },
  authRecoveryPassword: {
    createNewPassword: 'Создайте пароль',
    create: 'Создать',
    first: 'Новый пароль',
    second: 'Повторите',
    titleOfDemands: 'Ваш пароль должен состоять из:',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру (0-9)',
    symbolDemand: 'один символ (!@#%&*.)',
    example: 'Пример',
    exampleSymbols: 'Vtlq3*fsdfhqwj*',
    length: '8',
    generatePassword: 'Сгенерировать пароль',
    sameField: 'Пароли должны совпадать',
  },
  userGroup: {
    name: 'Название',
    typeGroup: 'Тип группы',
    newTypeGroup: 'Новый тип групп',
    main: 'Основные',
    groupOfUsers: 'Группы пользователей',
    listOfUsers: 'Список пользователей',
    operatorRoom: 'Операторская',
    newUserGroup: 'Новая группа',
    actions: 'Действия',
    countUsers: 'Кол-во пользователей',
    manager: 'Тим лидер/Руководитель',
    users: 'Пользователи',
    user: 'Пользователь',
    show: 'Показать',
    groups: 'Группы',
    addUserType: 'Добавить тип группы',
    itemsTypeShow: {
      all: 'Все ({count})',
      onlyChosen: 'Только выбранные',
    },
    createUserGroup: 'Группа пользователей',
    editUserGroup: 'Группа пользователей',
    active: 'Активность',
    onBreakCount: 'Макс. количество на перерыве',
    saleType: 'Направление продаж',
    operator: 'Операторская',
    distributionPriority: 'Приоритет распределения',
    ascPriority: 'От более старых к свежим',
    descPriority: 'От более свежих к старым',
  },
  userGroupType: {
    name: 'Название',
  },
  user: {
    users: 'Пользователи',
    newUser: 'Новый пользователь',
    fullName: 'Имя',
    email: 'Email',
    actions: 'Действия',
    name: 'Имя',
    lastName: 'Фамилия',
    webs: 'Вэбы',
    main: 'Основные',
    password: 'Пароль',
    languages: 'Языки',
    countries: 'Страна',
    schedule: 'Норма часов',
    groups: 'Группы',
    groupTypes: 'Типы групп',
    offer: 'Оффер',
    dataAccess: 'Доступ к данным',
    role: 'Должность',
    permissions: 'Права',
    createUser: 'Пользователь',
    editUser: 'Пользователь',
    setUpAccessForRoleDesc: 'Настройка шаблона доступов для роли',
    operator: 'Оператор',
    workStartDate: 'Дата трудоустройства',
    dismissalDate: 'Дата увольнения',
    bonusCurrency: 'Валюта для расчета бонусов',
    loginCountry: 'Страна входа',
    offers: 'Офферы',
    isOperator: 'Оператор',
    grade: 'Грейд',
    cardNumber: 'Осн. карта для выплат',
    additionalCardNumber: 'Доп. карта для выплат',
    salaryType: 'Роль для расчета ЗП',
    actionsSuccess: 'Пользователи изменены',
    activateSuccess: 'Пользователей активировано: {count}',
    deactivateSuccess: 'Пользователей деактивировано: {count} ',
    lastLoginAt: 'Последний логин',
    checkEmail: 'Убедитесь, что email указан верно:',
    notAvailable: 'Недоступен',
    teamLeaderCC: 'Тим лидер КЦ',
    localLeaderCC: 'Локальный руководитель КЦ',
    operatorCC: 'Оператор КЦ',
    emailSent: 'Email успешно отправлен',
  },
  userRole: {
    userRoles: 'Должности и доступы',
    newUserRole: 'Новая должность',
    name: 'Название',
    actions: 'Действия',
    roleAccess: 'Доступы для роли',
    settingAccessRole: 'Настройка шаблона доступов для роли',
    createUserRole: 'Должность',
    editUserRole: 'Должность',
    userCount: 'Кол-во активных пользователей',
    addSecuritySettings: 'Доп. настройки безопасности',
    logout: 'Вылогин, если статус “Недоступен”',
    maxView: 'Макс. кол-во просмотров номеров в сутки',
    showFullPhone: 'Показ полного номер телефона',
  },
  offerGroup: {
    name: 'Название',
    status: 'Статус',
    main: 'Основные',
    offer: 'Оффер',
    offers: 'Офферы',
    groupOfOffers: 'Группы офферов',
    newOffer: 'Новая группа',
    actions: 'Действия',
    createOfferGroup: 'Группа офферов',
    editOfferGroup: 'Группа офферов',
  },
  error: {
    invalid: '{name} не является допустимым',
    required: '{name} обязательное поле',
    requiredIf: '{name} обязательное поле',
    email: 'Неверный формат Email',
    maxLength: 'Максимальная длина символов {length}',
    minLength: 'Минимальная длина символов {length}',
    maxValue: 'Максимальное значение: {value}',
    minValue: 'Минимальное значение: {value}',
    onlyNumbers: 'Вводить допустимо только цифры',
    onlyIntegerNumbers: 'Вводить допустимо только целые числа',
    onlyWords: 'Вводить допустимо только буквы',
    allow: 'Ваш пароль должен состоять из: ',
    sameField: 'Пароли должны совпадать',
    regexPassword: 'Неверный формат пароля',
    labelsLimit: 'Можно запросить не более {limit} лейблов за один раз',
    url: 'Неверный формат URL',
    dateFromLess: 'Дата "c" должна быть меньше чем дата "по"',
    dateToLess: 'Дата "по" должна быть больше чем дата "c"',
    commaSpaceRule: 'Начало и конец поля не должны содержать пробел или запятую',
    lengthDemand: 'минимум 8 символов',
    capitalDemand: 'одну заглавную букву',
    lowercaseDemand: 'одну строчную буквы',
    numberDemand: 'одну цифру <span class="black--text text--lighten-2">(0-9)</span>',
    symbolDemand: 'один символ <span class="black--text text--lighten-2">(!@#%&*.)</span>',
    latinDemand: 'Пароль содержит только латинские буквы',
    invalidValue: 'Неправильное значение',
    notBeEmpty: 'Значение не должно быть пустым',
    error403Title: 'Ой, произошла ошибка...',
    error403Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    error404Title: 'Ой, произошла ошибка...',
    error404Desc: 'Страница не существует или у вас нет прав доступа к ней.',
    emailExist: 'Такая почта уже есть в системе',
    macrosExist: 'Такое значение уже есть в системе',
    entityExists: 'Сущность с таким названием уже существует',
    errorComparePassword: 'Введите пароль, который не <br>использовали в этом аккаунте<br> ранее',
    systemUpdate: 'Обновление системы',
    systemUpdateDesc: 'Развернута новая версия системы. Для обеспечения оптимальной производительности и загрузки новейших функций обновите страницу.',
    refreshPage: 'Обновить страницу',
  },

  page: {
    settingsDashboard: 'Настройки',
    callCentreDashboard: 'Дашборд оператора',
    pageSettingsCountry: 'Страны',
    pageLanguageList: 'Языки',

    login: 'Авторизация',
    restore: 'Забыли пароль',
    qrCode: 'Настройка двухфакторной аутентификации',
    recoveryPassword: 'Восстановление пароля',
    createPassword: 'Создайте пароль',

    pageMain: 'Главная',

    pageFinanceList: 'Финансовые настройки',
    pageFinanceCreate: 'Финансовые настройки',
    pageFinanceEdit: 'Финансовые настройки',
    pageFinanceDashboard: 'Фин. настройки для стран',

    pageUserList: 'Пользователи',
    pageUserCreate: 'Пользователь',
    pageUserEdit: 'Пользователь',

    pageUserGroupList: 'Группы пользователей',
    pageUserGroupCreate: 'Группа пользователей',
    pageUserGroupEdit: 'Группа пользователей',

    pageProductList: 'Продукты',
    pageProductCreate: 'Продукт',
    pageProductEdit: 'Продукт',

    pageProductTypeList: 'Типы продуктов',
    pageProductTypeCreate: 'Тип продукта',
    pageProductTypeEdit: 'Тип продукта',
    pageProductTypeDashboard: 'Тип продукта',

    pageIpAddressList: 'IP адреса',
    pageIpAddressCreate: 'IP адрес',
    pageIpAddressEdit: 'IP адрес',
    pageIpAddressDashboard: 'IP адрес',

    pageOfferList: 'Офферы',
    pageOfferCreate: 'Оффер',
    pageOfferEdit: 'Оффер',

    pageOfferGroupList: 'Группы офферов',
    pageOfferGroupCreate: 'Группа офферов',
    pageOfferGroupEdit: 'Группа офферов',

    pageOfferNicheList: 'Ниши офферов',
    pageOfferNicheCreate: 'Ниша оффера',
    pageOfferNicheEdit: 'Ниша оффера',
    pageOfferNormsList: 'Нормы по офферам',

    pageUserRoleList: 'Должности и доступы',
    pageUserRoleCreate: 'Должности и доступы',
    pageUserRoleEdit: 'Должности и доступы',
    pageUserRoleDashboard: 'Должности и доступы',

    pageLeadList: 'Лиды',
    pageLeadCreate: 'Лид',
    pageLeadEdit: 'Лиды',

    pageRejectReasonList: 'Причины статусов',
    pageRejectReasonCreate: 'Причина статусов',
    pageRejectReasonEdit: 'Причина статусов',

    pageOrderList: 'Заявки',
    pageOrderCreate: 'Заявка',
    pageOrderEdit: 'Заявка',

    pageOrderOperatorList: 'Заявки оператора',
    pageOrderOperatorCreate: 'Заявка оператора',
    pageOrderOperatorEdit: 'Заявка оператора',

    pageOrderImport: 'Загрузить из файла',

    pageOrderTagList: 'Теги к заявке',
    pageOrderTagCreate: 'Тег к заявке',
    pageOrderTagEdit: 'Тег к заявке',
    pageOrderTagDashboard: 'Теги к заявкам',

    pageQuestionnaireKnl: 'Анкета КЛН',
    pageQuestionnaireKnlEdit: 'Анкета оценивания КЛН',

    pageKlnAssessmentList: 'Оценки КЛН',
    pageKlnAssessmentCreate: 'КЛН по заявке',
    pageKlnAssessmentEdit: 'КЛН по заявке',

    pageKlnStatistic: 'Статистика КЛН',

    pageKlnOperatorAssessmentList: 'КЛН оператора',
    pageKlnOperatorAssessmentCreate: 'КЛН по заявке',
    pageKlnOperatorAssessmentEdit: 'КЛН по заявке',

    pageMetashipAccounts: 'Аккаунты Metaship',
    pageMetashipAccountsCreate: 'Аккаунт Metaship',
    pageMetashipAccountsEdit: 'Аккаунт Metaship',

    pageCityList: 'Населенные пункты',
    pageCityCreate: 'Населенный пункт',
    pageCityEdit: 'Населенный пункт',

    pageProductGiftList: 'Каталоги',
    pageProductGiftCreate: 'Каталог',
    pageProductGiftEdit: 'Каталог',

    pageProductSetsList: 'Наборы продуктов',
    pageProductSetsCreate: 'Наборы продуктов',
    pageProductSetsEdit: 'Наборы продуктов',

    pageTicketTypesList: 'Типы тикетов',
    pageTicketTypesCreate: 'Тип тикета',
    pageTicketTypesEdit: 'Тип тикета',

    pageGroupTypesList: 'Типы групп пользователей',
    pageGroupTypesCreate: 'Типы групп пользователей',
    pageGroupTypesEdit: 'Типы групп пользователей',
    pageGroupTypesDashboard: 'Типы групп',

    pageSalesScriptList: 'Скрипты',
    pageSalesScriptCreate: 'Скрипт',
    pageSalesScriptEdit: 'Скрипт',

    pageSalesScriptTemplateList: 'Шаблоны разделов скриптов',
    pageSalesScriptTemplateCreate: 'Шаблон',
    pageSalesScriptTemplateEdit: 'Шаблон',

    pagePhoneCodesList: 'Телефонные коды',
    pagePhoneCodesCreate: 'Телефонный код',
    pagePhoneCodesEdit: 'Телефонный код',

    pageDeliveryPointsList: 'Точки доставки',
    pageDeliveryPointsCreate: 'Точка доставки',
    pageDeliveryPointsEdit: 'Точка доставки',
    pageDeliveryPointsImport: 'Импорт точек доставки',

    pageDeliveryIntervalList: 'Интервалы доставки',
    pageDeliveryIntervalCreate: 'Интервал доставки',
    pageDeliveryIntervalEdit: 'Интервал доставки',

    pageLandingList: 'Лендинги',
    pageLandingCreate: 'Лендинг',
    pageLandingEdit: 'Лендинг',

    PageDeliveryWeekendList: 'Выходные дни',
    PageDeliveryWeekendCreate: 'Выходной день',
    PageDeliveryWeekendEdit: 'Выходной день',

    pageStorages: 'Склады',
    pageSuppliers: 'Поставщики',
    pageDeliveryService: 'Службы доставки',
    pageDeliveryServiceCreate: 'Служба доставки',
    pageDeliveryServiceEdit: 'Служба доставки',

    pageStopWordsList: 'Стоп-слова',

    pageOrganization: 'Юридические лица',

    pageBlackPhoneList: 'Черный список',
    pageBlackPhoneCreate: 'Черный список',
    pageBlackPhoneEdit: 'Черный список',

    pageTicketsList: 'Тикеты',
    pageTicketCreate: 'Тикет',
    pageTicketEdit: 'Тикет',

    pageMyTicketsList: 'Мои тикеты',
    pageMyTicketCreate: 'Тикет',
    pageMyTicketEdit: 'Тикет',

    pageDeliveryWarehouseList: 'Склады',
    pageDeliveryWarehouseCreate: 'Склад',
    pageDeliveryWarehouseEdit: 'Склад',
    pageDeliveryWarehouseImport: 'Импорт остатков',

    pageRegionList: 'Области',
    pageRegionCreate: 'Область',
    pageRegionEdit: 'Область',

    pageOrderFraudList: 'Статистика Некорректных',
    pageApproveSpeed: 'Скорость апрува',

    pageWorkTimeList: 'Учет времени',
    pageWorkTimeMeta: 'Учет времени и эффективность',
    pageLimitTimeEdit: 'Лимиты времени оператора',

    pageLogAuthList: 'Логи логинов/логаутов',
    pageLogOrderList: 'Логи заявок',

    pageQuestionnairesList: 'Анкеты',
    pageQuestionnairesCreate: 'Анкета',
    pageQuestionnairesEdit: 'Анкета',

    pageQuestionnairesOrderList: 'Анкетирование',
    pageLegalPersonsStats: 'Статистика по юр. лицам',

    pageSalaryBonusList: 'Матрица для осн. ЗП КЦ',
    pageSalaryBonusDashboard: 'Матрица для основной ЗП КЦ',
    pageSalaryBonusImport: 'Импорт данных для ЗП КЦ',
    // заглушки пока что
    pageLogs: 'Логи',
    pageTagsToOrders: 'Теги к Заявкам',
    pageQuestionnaires: 'Анкеты',

    pageOrderTrash: 'Необработанный треш',
    pageOrderTrashChecked: 'Обработанный треш',
    pageOrderTrashDispute: 'Спорный треш',

    pageOperatorGrades: 'Грейды операторов',

    main: 'Основные',
    general: 'Общее',
    callCentre: 'Колл-центр',
    logistics: 'Логистика',
    finance: 'Финансы',

    pageCampaign: 'Кампании прозвона',
    pageCampaignCreate: 'Кампания прозвона',
    pageCampaignCreateManual: 'Кампания прозвона ручная',
    pageCampaignEdit: 'Кампания прозвона',

    pageRepeatedConfirm: 'Повторные подтверждения',
    pageRepeatedConfirmCreate: 'Повторные подтверждения',
    pageRepeatedConfirmEdit: 'Повторные подтверждения',

    pageCityImport: 'Импорт населенных пунктов',

    pageCurrencyConversion: 'Конвертация валют',
    pageCurrencyConversionDashboard: 'Курс валют',
    pageCurrencies: 'Валюты',

    pageSmsTemplate: 'SMS шаблоны',
    pageSmsTemplateCreate: 'Шаблон SMS',
    pageSmsTemplateEdit: 'Шаблон SMS',

    pageDistrictList: 'Районы',
    pageDistrictCreate: 'Район',
    pageDistrictEdit: 'Район',

    exportReports: 'Экспорт отчетов',
    exportReportsList: 'Список отчетов',

    pageUserPenaltyList: 'Штрафы операторов',
    pageUserPenaltyCreate: 'Штраф',
    pageUserPenaltyEdit: 'Штраф',

    pageManagerDashboard: 'Дашборд',
    pageLogisticsManagerDashboard: 'Дашборд менеджмента',
    pageCallCentreManagerDashboard: 'Дашборд менеджмента',

    pageSmsCampaign: 'SMS кампании',
    pageSmsCampaignManual: 'SMS кампания ручная',
    pageSmsCampaignAuto: 'SMS кампания автоматическая',

    pageDelivery: 'Доставка',
    pageDeliveryCourier: 'Курьерская доставка',
    pageDeliveryPoints: 'Пункт выдачи',
    pageDeliveryPost: 'Почта',

    pageSalesModelList: 'Модель продаж',
    pageSalesModelDashboard: 'Модели продаж',

    pageCostCC: 'Стоимость КЦ',
    pagePrepayment: 'Аванс 1 операторов',
    pagePrepaymentTwo: 'Аванс 2 операторов',

    pagePrepaymentTeamLead: 'Аванс 1 тим лидов',
    pagePrepaymentTeamLeadTwo: 'Аванс 2 тим лидов',

    pageUserSalary: 'ЗП операторов',

    pageEfficiencyOfAdditionalProjects: 'Эффективность доп. проектов',

    pageWebAnalytica: 'Выплаты вебам',

    pagePaymentHoldList: 'Удержания',
    pagePaymentHoldCreate: 'Удержание',
    pagePaymentHoldEdit: 'Удержание',

    pagePaymentProfitList: 'Начисления',
    pagePaymentProfitCreate: 'Начисление',
    pagePaymentProfitEdit: 'Начисление',

    pageExcludedOffersForSDList: 'Исключенные офферы для СД',
    pageExcludedOffersForSDCreate: 'Оффер для СД',
    pageExcludedOffersForSDEdit: 'Оффер для СД',

    pageNotFound: '404',

    pageError403: 'Ошибка доступа',
    pageError404: 'Страница не найдена',

    pageHangingRansom: 'Зависший выкуп',
    pageUnpaidOrders: 'Заказы с зависшим выкупом',

    pageDistrictImport: 'Импорт районов',
    pageRegionImport: 'Импорт областей',

    pageAverageCheck: 'Средние чеки',

    pageDistributionCheck: 'Распределение чеков',

    pageBuybackDynamics: 'Динамика выкупа по дням',

    pageRejectedStatistics: 'Статистика отклоненных',

    pageCallController: 'Звонки',

    pageCallAnalytics: 'Аналитика по звонкам',

    pageDeliveryServiceAnalytic: 'Аналитика служб доставки',

    pageCallCentreStatistics: 'Статистика КЦ',

    pageOperatorEfficiency: 'Эффективность оператора',

    pageOperatorStatistics: 'Статистика оператора',

    pageSuspiciousOrdersList: 'Подозрительные заявки',

    pageReconfirmationStatistic: 'Повторные подтверждения',

    pageCallStatistics: 'Статистика по звонкам',

    pageCallStatisticsOld: 'Статистика по звонкам (old)',

    pageCcDashboardStatistics: 'Загрузка КЦ',

    pageDeliveryTaxList: 'Прочие комиссии',

    pageLogisticPrice: 'Стоимость логистики',
    pageLogisticPriceImport: 'Импорт Стоимость логистики',

    pageCallCenterPrice: 'Стоимость КЦ',

    pageCallCenterPriceImport: 'Импорт Стоимость КЦ',

    pageDeliveryTaxImport: 'Импорт Прочие комиссии',

    pageFinancialReport: 'Финансовый отчет',

    pageFinancialOrdersReport: 'Заказы для фин. отчета',

    pagePermission: 'Контроль доступов',
    pageQuestionnairesOrderEdit: 'Анкетирование',
    pageViewPermission: 'Контроль доступов',

    pageDostavim: 'Отчет почта-dostavim',

    pagePenaltyList: 'Штрафы и премии операторов',

    pageNoticeDepartment: 'Уведомления',

    pageLogisticsOrders: 'Логистика',

    pageLogWatchNumberList: 'Логи просмотра телефонов/заявок',

    pageLogAccounts: 'Логи аккаунтов',

    pageLogAbnormalList: 'Подозрительные просмотры',

    pageUserScheduleList: 'Нормы часов и графики работы операторов',
    pageUserScheduleListShort: 'Нормы часов и графики работы оп.',

    pageUserScheduleCreate: 'Норма часов и график работы операторов',

    pageOperatorSchedule: 'График работы операторов',

    pageSmsSettings: 'Настройки SMS',

    pageAutocall: 'Автообзвон',
    pageSmsChain: 'Цепочки уведомлений',
    pageSmsChainCreate: 'Новая цепочка уведомлений',
    pageSmsChainEdit: 'Цепочка уведомления',
  },
  country: {
    status: 'Статус',
    country: 'Страна',
    countries: 'Страны',
    create: 'Создание страны',
    editCountry: 'Редактирование страны',
    code: 'Код',
    rowsOn: 'На странице',
    phoneCode: 'Телефонный код',
    activity: 'Активность',
    form: {
      name: 'Название',
      countryCode: 'ISO код',
      phoneCode: 'Телефонный код',
    }
  },
  currencies: {
    title: 'Валюты',
    code: 'Код',
    currencyName: 'Название валюты',
    editing: 'Редактирование',
    errorFetchingCurrencies: 'Произошла ошибка при загрузке валют',
  },
  languages: {
    languages: 'Языки',
    status: 'Статус',
    country: 'Страна',
    code: 'Код',
    rowsOn: 'На странице',
    name: 'Название',
  },
  productType: {
    main: 'Основные',
    name: 'Название',
    typeOneC: 'Эквивалент 1С',
    description: 'Описание',
    addField: 'Добавить поле',
    createProductType: 'Тип продукта',
    composition: 'Состав',
    contraindications: 'Противопаказания',
    editProductType: 'Тип продукта',
    groupOfProducts: 'Типы продуктов',
    newProduct: 'Новый тип',
    actions: 'Действия',
    updateList1C: 'Обновить список типов 1С',
    successUpdate: 'Список типов 1С успешно обновлен',
  },
  ipAddress: {
    main: 'Основные',
    name: 'Название',
    ipAddress: 'IP Адрес',
    actions: 'Действия',
  },
  product: {
    main: 'Основные',
    pictures: 'Изображения',
    products: 'Продукты',
    newProduct: 'Новый продукт',
    actions: 'Действия',
    idOneC: 'ID Внешний',
    updateData: 'Обновить данные',
    name: 'Название',
    type: 'Тип продукта',
    alternativeName: 'Альтернативное название',
    typeOneC: 'Тип продукта (СРМ)',
    typeCallCenter: 'Тип продукта КЦ',
    offers: 'Офферы',
    scriptName: 'Название для скриптов',
    country: 'Страна',
    addPicture: 'Добавить изображение',
    general: 'Общее',
    additional: 'Дополнительно',
    createProduct: 'Продукт',
    editProduct: 'Продукт',
    invalidProductOneC: 'Указанный ID не найден.',
    productTypeOneCBad: 'БАД',
    productTypeOneCNormal: 'нормальный',
    activity: 'Активность',
    hideEmptyFields: 'Скрыть',
    showEmptyFields: 'Раскрыть',
    invalidFields: 'У вас не заполнены обязательные поля во вкладке “Общее”.',
  },
  lead: {
    newLead: 'Новый лид',
    actions: 'Действия',
    leads: 'Лиды',
    name: 'Имя',
    lastName: 'Фамилия',
    patronymic: 'Отчество',
    gender: 'Пол',
    fullName: 'ФИО',
    age: 'Возраст',
    phone: 'Телефон',
    male: 'Мужской',
    female: 'Женский',
    clientData: 'Данные клиента',
    createLead: 'Обзор лида',
    editLead: 'Редактировать лида',
  },
  offer: {
    general: 'Общее',
    main: 'Основное',
    products: 'Продукты',
    hotOffers: 'Офферы',
    offers: 'Офферы',
    newOffer: 'Новый оффер',
    actions: 'Действия',
    active: 'Активные',
    idOneC: 'ID 1C',
    deliveryCost: 'Стоимость доставки',
    setCost: 'Стоимость набора',
    set: 'Набор',
    cloneOffer: 'Дублировать оффер',
    cloneOfferSuccess: 'Оффер успешно дублирован',
    setCostItem: 'Стоимость ед.',
    updateData: 'Синхронизировать',
    name: 'Название',
    productSets: 'Наборы продуктов',
    removeSet: 'Удалить набор',
    alternativeName: 'Альтернативное название',
    groupOperators: 'Группы операторов',
    operatorGrade: 'Грейды операторов',
    niche: 'Ниша',
    niches: 'Ниши',
    product: 'Продукт',
    productType: 'Тип продукта',
    mainProductType: 'Тип основного продукта',
    productCountry: 'Страна',
    additional: 'Связанные офферы кампании',
    related: 'Связанные офферы',
    priorityOperators: 'Приоритет операторов',
    groupOffers: 'Группы офферов',
    addProduct: 'Добавить продукт',
    addProductSet: 'Добавить набор',
    createOffer: 'Оффер',
    editOffer: 'Оффер',
    salesType: 'Тип продаж',
    salesModel: 'Модель продаж',
    status: 'Статус',
    companies: 'Кампании',
    languages: 'Языки',
    invalidFields: 'У вас не заполнены обязательные поля во вкладке “{tab}”.',
    offerTargets: 'Целевые показатели по офферу',
    avgCheck: 'Средний чек',
    approveCheck: 'Чек апрува',
    approvePercent: 'Процент апрува',
    ransomPercent: 'Процент выкупа',
    offer: 'Оффер',
    campaign: 'Кампания',
    tabPriorityOperators: {
      filterList: {
        inputUsers: 'Пользователи',
        inputGrades: 'Грейды операторов',
        inputGroups: 'Группы операторов',
        placeholder: 'Ввод',
      },
      tableList: {
        number: 'ID',
        name: 'Ф.И.О',
        grade: 'Грейд',
        groups: 'Группа',
        priority: 'Приоритет',
        resetRating: 'Восстановить автоматический расчет приоритета',
        editText: 'Изменить',
      },
    },
  },
  dialogImageCropper: {
    addPicture: 'Добавить фото',
    cancel: 'Отменить',
    save: 'Сохранить',
    crop: 'Обрезать',
    name: 'Название',
    addImg: 'Добавить изображение',
  },
  offerNiche: {
    name: 'Название',
    status: 'Статус',
    main: 'Основные',
    nicheOfOffers: 'Ниши офферов',
    newNiche: 'Синхронизировать с 1С',
    actions: 'Действия',
    createOfferNiche: 'Создать нишу оффера',
    editOfferNiche: 'Редактировать нишу оффера',
    offer: 'Оффер',
    offers: 'Офферы',
  },
  order: {
    cloneOrders: 'Клонировать заявки',
    order_processing_time: 'Время обработки заказа',
    orderSubstatus: 'Подстатус',
    callbackAt: 'Следующий звонок',
    callback: 'Колбек',
    commentToPostman: 'Комментарий почтальону',
    sync: 'Синхронизация',
    orderSync: 'Синхронизация заявок',
    lastStatusDate: 'Дата статуса',
    sendSms: 'Отправка СМС',
    warehouse: 'Склад',
    historyDate: 'Дата исторического статуса',
    statusAndSub: 'Статус/подстатус',
    callbackDateTime: 'Дата и время колбэка',
    additionalProducts: 'Доп. продукты',
    unsavedChanges: 'Есть несохраненные изменения',
    currentOperatorList: 'В данный момент заявку обрабатывает',
    currentOperatorConfirm: 'Если вы продолжите редактирование, внесенные данные могут быть потеряны. Продолжить редактирование?',
    downloadInvoice: 'Скачать PDF-инвойс',
    downloadInvoicesBtn: 'Экспорт инвойсов',
    downloadInvoicesZip: 'Скачать инвойсы в ZIP-архив',
    downloadInvoicesPdf: 'Скачать инвойсы в PDF-файл',
    restart: 'Перезапустить',
    invoice: 'Инвойс',
    action: 'Действия',
    name: 'Имя',
    orders: 'Заявки',
    sms: 'SMS',
    favorite: 'Избранное',
    favorites: 'Избранные',
    isSyncedSuccessfully: 'Успешно синхронизированы',
    newOrder: 'Новая заявка',
    cpaId: 'CPA ID',
    tracker: 'Трекер',
    carrierId: 'ID перевозчика',
    createOrder: 'Заявка',
    editOrder: 'Редактировать заявку',
    shippingInfo: 'Данные доставки',
    dataClient: 'Данные клиента',
    openingCard: 'Открытие карточки',
    watchNumber: 'Просмотр номера',
    country: 'Страна',
    age: 'Возраст',
    gender: 'Пол',
    birthdayDate: 'Дата рождения',
    countries: 'Страны',
    region: 'Область',
    regions: 'Области',
    district: 'Район',
    city: 'Населенный пункт',
    cityName: 'Населенный пункт',
    street: 'Улица',
    phone: 'Телефон',
    createdAt: 'Дата заявки',
    lastSet: 'Последний набор',
    lastCall: 'Последний звонок',
    date: 'Дата',
    productsInOrder: 'Продукты в составе заказа',
    countryOrLanguage: 'Страна/язык',
    legalPerson: 'Юр. лицо',
    trackingCode: 'Штрихкод',
    telephonyStatus: 'Результат звонка',
    statusCall: 'Статус звонка',
    calls: 'Звонков',
    comments: 'Комментарий',
    groups: 'Группы',
    reason: 'Причина',
    noProductsByType: 'нет продуктов указанного типа',
    startDispute: 'Оспорить',
    disputeStarted: 'Спор открыт',
    house: 'Дом',
    web: 'Веб',
    webs: 'Вебы',
    kln: 'КЛН',
    block: 'Корпус',
    client: 'Клиент',
    address: 'Адрес',
    other: 'Другое',
    delivery: 'Доставка',
    orderPrice: 'Цена',
    productCount: 'шт',
    orderCar: 'Корзина заказа',
    orderTags: 'Теги',
    apartment: 'Квартира',
    postalCode: 'Почтовый индекс / Zip',
    courierComment: 'Комментарий курьеру',
    postmanComment: 'Комментарий почтальону',
    sendDate: 'Дата отправки',
    firstCallAt: 'Первый звонок',
    lastCallAt: 'Последний звонок',
    durationCall: 'Длительность разговора',
    countCall: 'Количество звонков',
    callAuto: 'Звонок автонабор',
    callManual: 'Звонок вручную',
    recordCall: 'Запись звонка',
    deliveryType: 'Способ доставки',
    deliveryTypes: 'Способы доставки',
    prepayment: 'Предоплата',
    prepaymentIncluded: 'Предоплата включена',
    paymentByTerminal: 'Оплата терминалом',
    deliveryService: 'Служба доставки',
    deliveryServices: 'Службы доставки',
    deliveryPoint: 'Точка доставки',
    deliveryDate: 'Дата доставки',
    shippedStatusDate: 'Дата отправки',
    deliveryTime: 'Время доставки',
    deliveryTimeFrom: 'Время доставки с',
    deliveryTimeTo: 'Время доставки по',
    deliveryInterval: 'Интервал доставки',
    postalAddress: 'Почтовое отделение',
    deliveryIncluded: 'Доставка включена',
    firstName: 'Имя',
    lastName: 'Фамилия',
    patronymic: 'Отчество',
    status: 'Статус',
    statuses: 'Статусы',
    script: 'Cкрипт',
    products: 'Продукты',
    product: 'Продукт',
    history: 'История',
    questionnaires: 'Анкеты',
    comment: 'Поле для комментариев',
    dateTimeCreated: 'Дата и время создания',
    sourceOrder: 'Источник заявки',
    regionOfClient: 'Регион клиента',
    responsibleOperator: 'Ответственный оператор',
    autodetectPhone: 'Автоопределение региона клиента',
    autoTimeClient: 'Автоопределение времени клиента',
    tags: 'Теги',
    userGroups: 'Группы пользователей',
    offer: 'Оффер',
    offers: 'Офферы',
    offerGroups: 'Группы офферов',
    offerNiches: 'Ниши офферов',
    niches: 'Ниши',
    rejectReason: 'Причина статуса',
    rejectReasons: 'Причины статусов',
    rejectReasonCanceledRC: 'Отмена ПП',
    callStatus: 'Статус дозвона',
    callStatuses: 'Статусы дозвона',
    activity: 'Активность',
    landing: 'Лендинг',
    landings: 'Лендинги',
    operator: 'Оператор',
    operatorFirstApprove: 'Оператор 1-го апрува',
    orderOperator: 'Оператор заказа',
    operators: 'Операторы',
    number: 'Номер',
    phoneList: 'Список номеров',
    ordersByNumber: 'Заказы по номеру',
    noOrdersByNumber: 'Нет заказов',
    typeOrOperator: 'Вид/оператор',
    checkPrice: 'Чек заказа',
    currencyName: 'Название валюты',
    type: 'Вид',
    general: 'Общий',
    additional: 'Дополнительно',
    cpaData: 'Данные из СРА сети',
    cpaDataTooltip: {
      cpaId: 'ID заказа из СПА сети',
      webId: 'ID веба',
      leadPrice: 'Cтоимость рекламы за лид',
    },
    tracking: 'Трекинг',
    createTicket: 'Создать тикет',
    createReclamation: 'Создать рекламацию',
    createTicketByClient: 'Создать тикет по клиенту',
    historyOfOrder: 'История по заявке',
    noLogOrders: 'Нет истории',
    noSmsFound: 'Нет СМС',
    callbackReason: 'Причина колбэка',
    statusReason: 'Причина статуса',
    was: 'Было',
    became: 'Стало',
    system: 'Система',
    orderList: 'Состав заказа',
    orderBundle: 'Состав набора',
    sum: 'Сумма',
    allSum: 'Сумма (всего)',
    productsPrice: 'Стоимость товара',
    deliveryPrice: 'Стоимость доставки',
    orderWillBeDeliveredWithin: 'Заказ будет доставлен вам в течение {min}-{max} дней',
    male: 'Муж',
    female: 'Жен',
    callbackDate: 'Дата',
    callbackTime: 'Время',
    listNumbers: 'Список номеров',
    callToLead: 'Вызов лида',
    makeCall: 'Позвонить',
    callInProgress: 'Идет звонок',
    shouldCall: 'Выполнить вызов лида?',
    abounentIsNotAvailable: 'Абонент недоступен',
    abounentIsNotAvailableDescription: 'На данный момент абонент занят или недоступен...',
    callAgain: 'Перезвонить',
    attention: 'Внимание',
    mediaDeviceError: 'Вы не дали доступ к устройствам ввода и вывода аудио в настройках браузера.',
    mediaConnectStep1: 'Нажмите иконку слева от браузерной строки',
    readyStatusError:
      'Для выбора статуса <b>"Готов"</b> вы должны быть подключены к телефонии. Для настройки подключения обратитесь к своему менеджеру или в технический отдел',
    mediaConnectStep2: 'В появившимся меню нажмите «Сбросить доступы»',
    mediaConnectStep3: 'Перезагрузите страницу браузера',
    noOktellConnection: 'Нет соединения с сервером Oktell',
    suspiciousClientTitle: 'Этот клиент уже имеет {amount} отказ',
    suspiciousClientTitleMultiple: 'Этот клиент уже имеет {amount} отказа',
    suspiciousClientTitleMultiple5: 'Этот клиент уже имеет {amount} отказов',
    suspiciousClientSubtitle: 'Обратите внимание на историю заказов перед продолжением.',
    oktellConnectionError: 'Oшибка подключения',
    oktellOffline: 'Офлайн',
    oktellOnline: 'Онлайн',
    sendMessage: 'Отправить SMS',
    actions: {
      actions: 'Действия',
      changeStatus: 'Сменить статус на',
      substatus: 'Подстатус',
      addTags: 'Добавить теги',
      removeTags: 'Удалить теги',
      removeProduct: 'Удалить продукт из заказа',
      statusReason: 'Причина статуса',
      callback: 'Назначить колбек на',
      approveByName: 'Апрув от имени',
    },
    sourceTitle: {
      cpa: 'СРА',
      manual: 'Вручную',
      importFile: 'Из файла',
      campaign: 'Доп. проекты',
    },
    sourceCPA: 'СРА',
    sourceAddProject: 'Доп. проекты',
    sourceIncome: 'Входящая',
    sourceUser: 'Ручное создание',
    actionsSuccess: 'Действия применены к выбранным заявкам',
    ticketsCreated: 'Было создано {count} тикета(ов)',
    forPackaging: 'На упаковку',
    filterByDate: 'Фильтр по дате',
    hoursFromApprove: 'Более N-часов',
    synchronizationDate: 'Дата синхронизации',
    isSynced: 'Заявки синхронизированы',
    synchronizeIncorrectly: 'Стоимость соответствует',
    warehouses: 'Склады для отправки',
    dateFilterType: {
      createdAt: 'По заходу',
      approvedAt: 'По апруву',
      shippedAt: 'По отправке (дата последней отправки)',
      paidAt: 'По оплате',
    },
    firstApprovedAt: 'Дата апрува',
    shippedDate: 'Дата отправки заказа',
    packerTask: 'Задание упаковщику',
    autoComment: 'Автокомментарий',
    callbackListEmpty: 'У вас нет запланированных колбеков.',
    statusDeliveryService: 'Статус службы доставки',
    commentAnother: 'Комментарий',
    ageRules: 'Max возраст 120',
    helpTeamLead: 'Запрос помощи тимлиду успешно отправлен',
    deliveryServiceName: 'Служба доставки',
    metashipStatus: 'Статус СД',
    metashipStatusDate: 'Дата статуса СД',
    tooltipVIcon: 'Обнаружено несоответствие в скрипте и оффере.',
    attribute: 'Атрибуты',
    attributes: {
      hot: 'Горячие',
      cold: 'Холодные',
      no_ds: 'Без СД',
      trash_geo: 'Трешовые ГЕО',
      cancel: 'Отмена 72',
      approve_after_cancel: 'Апрув после отмены',
      no_manager: 'Без менеджера',
      call_queue_error: 'Ошибка добавления в очередь прозвона',
    },
  },
  rejectReason: {
    rejectReasons: 'Причины статусов',
    newRejectReason: 'Новая причина',
    name: 'Название',
    actions: 'Действия',
    createRejectReason: 'Причина отмены',
    editRejectReason: 'Причина отмены',
    editStatusReason: 'Причина статусов',
    main: 'Основные',
    trashMain: 'Треш',
    toOperator: 'Причину указывает оператор',
    toOperatorTableHead: 'Указывает оператор',
    explanation: 'Разъяснение',
    description: 'Разъяснение',
    textForCpa: 'Текст для СРА',
    applicableStatuses: 'Применима к статусам',
    correspondsSystem: 'Соответсвует системной',
    correspondsSystemReason: 'Соответствует системной причине "некорректный"',
    incorrect: 'Некорректный',
    canceled: 'Отмена',
    trash: 'Треш',
    trashForm: 'Треш',
    callback: 'Колбэк',
    in_blacklist: 'Номер в черном списке',
    invalid_phone: 'Не валидный номер',
    stop_word: 'Стоп-слова в заявке',
    duplicate: 'Дубликат',
    processing: 'Обработка',
    reason_wrong_geo: 'Неправильное гео',
    reason_test_lead: 'Тестовый лид',
    reason_dublicate_order: 'Дублирующий заказ',
    reason_could_not_get_through: 'Не удалось дозвониться',
    reason_possibly_froud: 'Возможно фрауд',
    reason_wrong_ads: 'Неправильные объявления',
    reason_did_not_order: 'Не заказывал',
    reason_incorrect_phone: 'Неправильный телефон',
    reason_wrong_targeting: 'Неправильный таргетинг',
    reason_consultation: 'Консультация',
    reason_expensive: 'Дорого',
    reason_changed_his_mind: 'Передумал',
  },
  productGift: {
    createProductGift: 'Каталог',
    editProductGift: 'Каталог',
    productGifts: 'Каталоги',
    active: 'Активность',
    newProductGift: 'Новый каталог',
    catalog: 'Каталог',
    actions: 'Действия',
    main: 'Основные',
    name: 'Название',
    country: 'Страна',
    product: 'Продукт',
    settings: 'Настройки',
    offers: 'Офферы',
    storages: 'Склады',
    offerGroups: 'Группы офферов',
    offerExclusion: 'Исключенные офферы',
    addToMail: 'Почтовые заказы включены',
    mailOrders: 'Почтовые заказы',
    creator: 'Кто создал',
    creationDate: 'Дата создания',
    activeProductGift: 'Активные каталоги',
    productGiftInset: 'Вкладывать',
    productGiftNotInset: 'Не вкладывать',
    productGiftAll: 'Все',
  },
  phoneCodes: {
    codes: 'Телефонные коды',
    newCode: 'Новый код',
    code: 'Код',
    minNumber: 'Начало диапазона',
    maxNumber: 'Конец диапазона',
    timezone: 'Часовой пояс',
    time: 'Время',
    countryCode: 'Код страны',
    regionCode: 'Код региона',
    region: 'Название области',
    operator: 'Оператор связи',
    actions: 'Действия',
    phoneCode: 'Телефонный код',
    name: 'Название',
    internalCode: 'Внутренний код',
    timezoneName: 'Название часового пояса',
    regionDistrict: 'Регион/область',
    country: 'Страна',
    editPhoneCodes: 'Телефонный код',
  },
  city: {
    city: 'Населенные пункты',
    newCity: 'Новый пункт',
    createCity: 'Населенный пункт',
    main: 'Основные',
    create: 'Населеный пункт',
    country: 'Страна',
    region: 'Область',
    district: 'Район',
    deliveryService: 'Служба доставки',
    deliveryType: 'Тип Доставки',
    serviceName: 'Имя в сервисе',
    terminal: 'Терминал',
    locationId: 'Location ID',
    actions: 'Действия',
    editCity: 'Населенный пункт',
    deliveryServiceAndStorage: 'Служба доставки и склад',
    warehouse: 'Склад',
    refId: 'ref_id',
    availableTerminal: 'Наличие терминала',
    terminalPresent: 'Есть терминал',
    terminalAbsent: 'Нет терминала',
    terminalAll: 'Все',
    loadFromFile: 'Загрузить из файла',
    actionsModal: {
      actionType: 'Действие',
      addDeliveryService: 'Подключить СД',
      removeDeliveryService: 'Отключить СД',
      deliveryServices: 'Службы доставки',
      warehouses: 'Склады для подключаемой СД',
    },
    attention: 'Внимание',
    actionsError: 'Для нас.пункта и службы доставки может быть указан только один склад отправки.',
    actionsSuccess: 'Действия применены к выбранным населенным пунктам',
    postEnabledTill: 'Почта подключена до',
    modalDesc:
      'В системе уже существует сущность с таким названием и расположением. При создании дубликата возможны ошибки в дальнейшей работе системы.',
    cancel: 'Отменить',
    continue: 'Продолжить',
    filter: {
      terminalExist: 'Есть терминал',
      noTerminal: 'Терминала нет',
      all: 'Все',
    },
  },
  salesScript: {
    scripts: 'Скрипты',
    newScript: 'Новый скрипт',
    name: 'Название',
    general: 'Общее',
    script: 'Скрипт',
    preview: 'Предпросмотр',
    priceFormation: 'Ценообразование',
    exercisesCount: 'Кол-во уп. в курсе',
    coursePrice: 'Цены курсов',
    main: 'Основные',
    language: 'Язык',
    countries: 'Страны',
    offers: 'Офферы',
    offer: 'Оффер',
    version: 'Версия',
    sections: 'Разделы',
    salesType: 'Тип продаж',
    userGroups: 'Группы операторов',
    userGroupsDesc:
      'Выберите группу операторов для тестирования скрипта. Убедитесь, что выбранная группа операторов также добавлена в настройках телефонии для выбранных офферов.',
    operatorsDesc:
      'Выберите конкретных операторов для тестирования скрипта. Убедитесь, что выбранные операторы также добавлены в настройках телефонии для выбранных офферов.',
    salesModel: 'Модель продаж',
    users: 'Операторы',
    actions: 'Действия',
    createScript: 'Скрипт',
    editScript: 'Скрипт',
    sectionName: 'Имя',
    paragraphName: 'Имя',
    plainText: 'Обычный текст',
    addParagraph: 'Добавить параграф',
    addSelection: 'Добавить раздел',
    paragraphInSection: 'Параграфы в разделе',
    textOfParagraph: 'Текст параграфа',
    moves: 'Переходы',
    addGroup: 'Добавить группу',
    create: 'Создать',
    template: 'Шаблон',
    stylesTitle: 'Стили',
    clearStyles: 'Очистить стили',
    colorText: 'Цвет текста',
    macroses: 'Макросы',
    chooseStyleOfText: 'Выберите стиль текста',
    hideFromSection: 'Скрыть из оглавления',
    createFromTemplate: 'Создать из шаблона',
    saveSectionLikeTemplate: 'Сохранить раздел как шаблон',
    invalidFields: 'У вас не заполнены обязательные поля во вкладке “Общее”.',
    invalidFieldsSets: 'У вас не заполнены обязательные поля во вкладке “Наборы продуктов”.',
    modalDescRemovingParagraph:
      'На параграф есть переходы из других параграфов. Вы уверены, что хотите удалить выбранный параграф?',
    saveSectionAsTemplate: 'Сохранить раздел как шаблон',
    active: 'Активность',
    requestHelpTeamLeader: 'Запросить помощь тимлидера',
    templateFromSection: {
      name: 'Имя шаблона раздела',
      description: 'Описание',
    },
    transition: {
      transitionGroupName: 'Название группы переходов',
      nameOfBtn: 'Название кнопки',
      paragraphAfterMoving: 'Параграф после перехода',
      colorBtn: 'Цвет кнопки',
      type: {
        info: 'информация',
        success: 'позитив',
        warning: 'предупреждение',
        danger: 'негатив',
      },
    },
    styles: {
      name: 'Название',
      tooltip: 'Подсказка',
      editableStyles: 'Редактируемые стили',
      exampleText: 'Съешь ещё этих мягких французских булок, да выпей же чаю...',
    },
    macros: {
      name: 'Название',
      macroses: 'Макросы',
      systems: 'Системные',
      common: 'Общее',
      modalDescRemoving:
        'Удаленный макрос продолжит отображаться в тех скриптах, где он был использован.',
    },
    macrosStatus: {
      main: 'Основной',
      substitute: 'Заменитель',
      manually: 'Альтернативный',
      additional_cross: 'Доп. кросс',
      aware_cross: 'Кросс в курсе',
      another: 'Другой',
    },
    offerList: {
      offerList: 'Список офферов',
      groups: 'Группы офферов',
      niches: 'Ниши',
      show: 'Показать',
      offer: 'Оффер',
      niche: 'Ниша',
    },
  },
  financeSettings: {
    finance: 'Финансовые настройки',
    addFinance: 'Финансовые настройки',
    editFinance: 'Финансовые настройки',
    main: 'Основные',
    country: 'Страна',
    region: 'Область',
    currency: 'Валюта',
    timezone: 'Временная зона по умолчанию',
    postalService: 'Служба доставки по умолчанию',
    timezoneForm: 'Временная зона',
    deliveryCost: 'Стоимость доставки',
    actions: 'Действия',
    length: '10',
  },
  orderTag: {
    name: 'Название',
    main: 'Основные',
    actions: 'Действия',
    createOrderTag: 'Тег к заявке',
    editOrderTag: 'Тег к заявке',
    newTag: 'Новый тег',
    tags: 'Теги к заявке',
  },
  salesScriptTemplate: {
    scriptSectionTemplates: 'Шаблоны разделов скриптов',
    newScriptTemplate: 'Новый шаблон',
    name: 'Название',
    actions: 'Действия',
    createScriptTemplate: 'Шаблон',
    editScriptTemplate: 'Шаблон',
    sections: 'Разделы',
    paragraphsInSection: 'Параграфы в разделе',
    description: 'Описание',
    textOfParagraph: 'Текст параграфа',
    moves: 'Переходы',
    addGroup: 'Добавить группу',
    preview: 'Предпросмотр',
    active: 'Активность',
  },
  landing: {
    landing: 'Лендинги',
    addLanding: 'Добавить лендинг',
    removeLanding: 'Добавить лендинг',
    main: 'Основные',
    name: 'Название',
    url: 'Ссылка',
    offer: 'Оффер',
    landingEdit: 'Лендинг',
    landingCreate: 'Лендинг',
    forToday: 'За сегодня',
    forWeek: 'За неделю',
    forMonth: 'За Месяц',
    actions: 'Действия',
  },
  deliveryPoint: {
    created: 'Создал',
    createdAnother: 'Создано',
    lastEdited: 'Последний отредактировал',
    lastEditedAnother: 'Последнее редактирование',
    deliveryPoints: 'Точки доставки',
    deliveryPoint: 'Точка доставки',
    deliveryPointEdit: 'Точка доставки',
    addDeliveryPoint: 'Добавить',
    name: 'Название',
    deliveryType: 'Тип доставки',
    deliveryService: 'Служба доставки',
    terminal: 'Терминал',
    terminalPayment: 'Наличие терминала',
    city: 'Населенный пункт',
    region: 'Область',
    district: 'Район',
    country: 'Страна',
    active: 'Активность',
    locationId: 'Location ID',
    actions: 'Действия',
    postalCode: 'Индекс',
    main: 'Основные',
    keyIntegration: 'Ключ  интеграции',
    deliveryServiceAndStorage: 'Служба доставки и склад',
    service: 'Сервис',
    storage: 'Склад',
    loadFromFile: 'Загрузить из файла',
    minDay: 'Минимум дней',
    maxDay: 'Максимум дней',
    address: 'Адрес',
    phoneNumber: 'Телефон',
    comment: 'Комментарий',
    workTime: 'Время работы',
    status: 'Статус',
    activePoint: 'Активная',
    deactivatedPoint: 'Деактивированная',
    paymentByTerminal: 'Оплата терминалом',
    addTerminal: 'Подключить',
    removeTerminal: 'Отключить',
    actionsSuccess: 'Действия применены к выбранным точкам доставки',
    activateDescription: 'Подтвердите активацию выбранных точек доставки.',
    deactivateDescription: 'Подтвердите деактивацию выбранных точек доставки.',
    activateSuccess: 'Точек доставки активировано: {count}',
    deactivateSuccess: 'Точек доставки деактивировано: {count} ',
    updateData: 'Обновление информации по существующей точке',
  },
  orderImport: {
    loadFromFile: 'Загрузить из файла',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    noSyncedOrders: 'Не синхронизированные заявки: {orders}',
    createRelatedOrders: 'Создать заявки на связанный оффер',
    importWarehouseExample: 'Шаблон импорта остатков.xlsx',
    addFile: 'Добавить файл',
    preview: 'Предпросмотр',
    importData: 'Импортировать данные',
    status: 'Статус',
    offer: 'Оффер',
    webs: 'Вебы',
    country: 'Страна',
    language: 'Язык',
    tags: 'Теги',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    field: {
      age: 'Возраст',
      apartment: 'Номер квартиры',
      birthdayDate: 'Дата рождения',
      city: 'Город',
      errors: 'Информация',
      gender: 'Пол',
      house: 'Номер дома',
      lastName: 'Фамилия',
      name: 'Имя',
      offer: 'Оффер',
      orderPrice: 'Цена заказа',
      patronymic: 'Отчество',
      phone: 'Телефон',
      postalCode: 'Почтовый индекс',
      product: 'Продукт',
      street: 'Улица',
      trackingCode: 'Трек номер',
    },
  },
  deliveryInterval: {
    deliveryIntervals: 'Интервалы доставки',
    deliveryInterval: 'Интервал доставки',
    main: 'Основные',
    deliveryService: 'Служба доставки',
    city: 'Населенный пункт',
    deliveryPoint: 'Точка доставки',
    from: 'Интервал с',
    to: 'Интервал по',
    boundaryTime: 'Граничное время',
    deliveryPeriodMax: 'Максимально дней',
    deliveryPeriodMin: 'Минимально дней',
    deliveryPeriodMaxShort: 'Макс. дней',
    deliveryPeriodMinShort: 'Мин. дней',
    intervals: 'Интервалы',
    availableDeliveryDays: 'Доступные дни доставки',
    nameDeliveryService: 'Название СД',
  },
  stopWords: {
    stopWords: 'Стоп-слова',
    main: 'Основные',
    description: 'Описание',
    alertDescription: 'Перечислите стоп слова и/или фразы в текстовом поле через запятую',
  },
  deliveryWeekend: {
    deliveryWeekends: 'Выходные дни',
    deliveryWeekend: 'Выходной день',
    addDeliveryWeekend: 'Добавить выходной день',
    deliveryWeekendEdit: 'Выходной день',
    name: 'Название',
    actions: 'Действия',
    main: 'Основные',
    country: 'Страна',
    region: 'Область',
    district: 'Район',
    city: 'Населенный пункт',
    dateFrom: 'Дата с',
    dateTo: 'Дата по',
    deliveryServices: 'Служба доставки',
    active: 'Активность',
  },
  deliveryType: {
    courier: 'Курьер',
    courier_interval: 'Курьер (интервал)',
    post: 'Почта',
    pvz: 'ПВЗ',
    fulfillment: 'Фулфилмент',
  },
  deliveryServices: {
    nameColumn: 'Название',
    countriesColumn: 'Страны',
    countryColumn: 'Страна',
    regionColumn: 'Регион',
    cityColumn: 'Населенный пункт',
    serviceNameColumn: 'Имя в сервисе',
    deliveryService: 'Добавить склад',
    createDeliveryServicePageTitle: 'Служба доставки',
    editDeliveryServicePageTitle: 'Служба доставки',
    cityFilterTitle: 'Населенный пункт',
    warehouseFilterTitle: 'Склад',
    createEditFormTitle: 'Основные',
    idOneC: 'ID 1C',
    sync: 'Синхронизировать',
    sync1C: 'Синхронизировать с 1С',
    tooltipMessage:
      'При изменении этого поля, после нажатия на кнопку "Сохранить" будет выполнен запрос на обновление точек доставки в Metaship',
    syncProcessing: 'Выполняется синхронизация',
    syncSuccess: 'Синхронизация успешно выполнена.',
    syncFail:
      'Во время синхронизации возникла ошибка. Убедитесь, что \n' +
      'выбранный магазин работает с указанной службой доставки, \n' +
      'проверьте лог обновления точек доставки или обратитесь в \n' +
      'тех.поддержку.',
    name: 'Название',
    deliveryType: 'Способ доставки',
    type: 'Способ доставки',
    description: 'Описание',
    relatedCities: 'Населенные пункты',
    dayForPayment: 'Дней для оплаты',
    mainCountry: 'Основная страна обслуживания',
    updateDataMetaship: 'Обновлять точки доставки из Metaship?',
    yes: 'Да',
    no: 'Нет',
    metashipDSCode: 'Код СД в Metaship (deliveryService)',
    syncWithMetaship: 'Синхр. с Metaship',
    idMetaship: 'ID магазина в Metaship',
    tooltip:
      'При изменении этого поля, после нажатия на кнопку «Сохранить» будет выполнен запрос на обновление точек доставки в Metaship',
  },
  campaign: {
    title: 'Кампании прозвона',
    titleItem: 'Кампания прозвона',
    newCampaign: 'Добавить',
    addManual: 'Выбрать заявки вручную',
    filter: {
      offers: 'Офферы',
      salesType: 'Тип продаж',
      activity: 'Активность',
      countries: 'Страны',
    },
    table: {
      id: 'ID',
      name: 'Название',
      countryLang: 'Страна',
      typeSell: 'Тип продаж',
      date: 'Дата создания',
      nameCreator: 'Кто создал',
      status: 'Cтатус кампании',
      actions: 'Действия',
    },
    form: {
      title: 'Настройки кампании',
      titleEdit: 'Основное',
      titleEditList: 'Очередь на прозвон',
      name: 'Название',
      salesType: 'Тип продаж',
      country: 'Страна',
      language: 'Язык',
      questionnaire: 'Анкета',
      activity: 'Статус кампании',
      offers: 'Офферы',
      products: 'Продукты',
      startDate: 'Дата присвоения статуса с',
      endDate: 'Дата  присвоения статуса по',
      timeAfter: 'Время с момента присвоения статуса',
      timeAfterSkip: 'Не набирать, если прошло более',
      dayDelay: 'Дней задержки на 1 упаковку осн. товара',
      dayDelayNext: 'Шаг (дней) для каждой последующей упаковки',
      productQuantity: 'Количество упаковок',
      statuses: 'Статусы',
      absentOffer: 'Отсутсвует связанный оффер:',
      absentScript: 'Отсутсвует связанный скрипт:',
      scripts: 'Офферы',
    },
    queueList: {
      id: 'ID Заявки',
      status: 'Статус звонка',
      create: 'Созданная заявка',
      date: 'Дата/время',
    },
    manual: {
      title: 'Кампания прозвона ручная',
      form: {
        title: 'Основные',
        name: 'Название',
        offer: 'Оффер кампании',
        dateStart: 'Дата старта кампании',
        activity: 'Статус кампании',
      },
      filterOrder: {
        title: 'Фильтр заявки',
        salesType: 'Тип продаж',
        niche: 'Ниша',
        tags: 'Теги',
        country: 'Страна',
        offers: 'Офферы',
        language: 'Язык',
        offerGroups: 'Группы офферов',
        status: 'Статус',
        products: 'Продукты',
      },
      tableOrder: {
        id: 'Заказ №',
        niche: 'Ниша',
        offer: 'Оффер',
        operator: 'Оператор',
        operatorGroup: 'Группа оператора',
      },
      formManualCampaignErrorTitle: 'Внимание',
      formManualCampaignError: 'Добавьте один или несколько заказов в кампанию прозвона',
    },
  },
  productSets: {
    productSets: 'Наборы продуктов',
    createProductSets: 'Набор продуктов',
    editProductSets: 'Набор продуктов',
    newProductSets: 'Добавить',
    placeholderInput: 'Название',
    placeholderAlternativeTitle: 'Альтернативное название',
    nameForScripts: 'Название для скриптов',
  },
  blackPhone: {
    blackPhone: 'Черный список',
    newBlackPhone: 'Добавить',
    dateOfCreation: 'Дата создания',
    phone: 'Номер',
    author: 'Кто добавил',
    addToBlacklist: 'Добавить в черный список',
    phoneNumber: 'Номер телефона',
  },
  groupTypes: {
    groupTypes: 'Типы групп пользователей',
    newGroupTypes: 'Добавить',
    name: 'Название',
    groups: 'Группы',
    groupTypesAdd: 'Добавить тип группы',
    groupTypesCancel: 'Добавить',
  },
  tickets: {
    goToOrder: 'Перейти на заявку',
    creatBy: 'Создатель:',
    createData: 'Создан:',
    tickets: 'Тикеты',
    myTickets: 'Мои тикеты',
    ticket: 'Тикет',
    created: 'Создан',
    whoCreate: 'Кто создал',
    ticketCreatedBy: 'Создатель тикета',
    type: 'Тип',
    application: 'Заявка',
    priority: 'Приоритет',
    status: 'Статус',
    group: 'Группа',
    accountable: 'Ответственный',
    accountableGroupUsers: 'Ответственная группа пользователей',
    accountableGroup: 'Ответственная группа',
    accountableUser: 'Исполнитель',
    main: 'Основное',
    ticketType: 'Тип тикета',
    deadLine: 'Дата дедлайна',
    deadline: 'Дедлайн',
    createdAt: 'Тикет создан',
    timer: 'Обратный таймер',
    executor: 'Исполнитель',
    timeLeft: 'Осталось: менее ',
    lessThan: 'менее ',
    errorCalc: 'Ошибка определения даты статуса заявки.|Дедлайн равен 0.',
    executionPeriod: 'Срок выполнения',
    executionPeriodHours: 'Срок выполнения (часы)',
    problemDescription: 'Описание проблемы',
    commentAccountableUser: 'Комментарий исполнителя',
    dateCreation: 'Дата создания',
    titleCreate: 'Создать тикеты (рекламации)',
    operatorAbbreviation: 'оп. 1-го апрува',
    noOperator: 'оператор не закреплен',
    attributes: {
      title: 'Атрибуты',
      no_user: 'Без исполнителя',
      no_group: 'Без ответственной группы',
      no_deadline: 'Без дедлайна',
      overdue: 'Просроченные',
    },
    modal: {
      ticketType: 'Тип тикетов',
      titleDeadline:
        'Дедлайн для каждого тикета будет установлен автоматически, в соответстивии с типом тикета.',
      titleExecutor:
        'Исполнитель для каждого тикета будет установлен автоматически, в соответстивии с типом тикета.',
      title: 'Создать тикеты',
      titleGeneral:
        'Дедлайн и исполнитель для каждого тикета будет установлен автоматически, в соответствии с типом тикета',
    },
  },
  ticketType: {
    reclamation: 'Рекламация',
    client_request: 'Обращение клиента',
  },
  ticketPriority: {
    high: 'Высокий',
    medium: 'Средний',
    low: 'Низкий',
  },
  questionnaireKnl: {
    title: 'Анкета КЛН',
    crete: 'Добавить',
    shortName: 'Сокращенное название',
    addItem: 'Добавить пункт',
    itemName: 'Название пункта',
    name: 'Название',
    score: 'Баллы',
    evaluationOptions: 'Варианти оценки',
    actions: 'Действия',
    create: 'Создать',
    sections: 'Разделы',
    evaluationPoint: 'Пункты оценивания',
    settingsItems: 'Настройка пунктов оценивания',
    fullName: 'Полное название',
    numberOfSection: 'Порядковый номер раздела',
    evaluatingNameByValue: {
      yes: 'Дa',
      no: 'Нет',
      partial: 'Частично',
      dont_consider: 'Не учитывать',
    },
  },
  warehouseStock: {
    table: {
      outerId: 'ID Внешний',
      warehouse: 'Склад',
      name: 'Название',
      country: 'Страна',
      type: 'Тип продукта',
      amount: 'Кол-во',
    },
  },
  deliveryWarehouse: {
    warehouses: 'Склады',
    warehouse: 'Склад',
    name: 'Название',
    location: 'Расположение',
    remainingGoodsExport: 'Экспорт остатков',
    regionDelivery: 'Доставка в регионы',
    deliveryService: 'Службы доставки',
    actions: 'Действия',
    add: 'Добавить',
    main: 'Основные',
    sync: 'Синхронизировать',
    syncWith1C: 'Синхронизировать c 1C',
    idOneC: 'ID 1C',
    serviceName: 'Имя в сервисе',
    comment: 'Описание',
    countryLocation: 'Страна расположения',
    regionLocation: 'Регион расположения',
    manager: 'Руководитель подразделения',
    relatedDeliveryServices: 'Связанные службы доставки',
    city: 'Населенный пункт',
    region: 'Регион',
    country: 'Страна',
    modalTitle: 'Внимание',
    modalContent: 'Указанный id не найден',
    area: 'Области',
    amount: 'Кол-во',
    percent: '%',
    resultCol: 'Итого',
    postEnableHour: 'Включить почту на N часов',
    table: {
      table: {
        name: 'Название',
        barcode: 'Штрихкод',
        id: 'ID',
        inStock: 'Кол-во',
        warehouse: 'Склад',
        errors: 'Информация',
      },
    },
  },
  orderFraud: {
    orderFraud: 'Статистика Некорректных',
    applicationSource: 'Источник заявки',
    totalApplications: 'Всего заявок',
    incorrect: 'Некорректных',
    percentIncorrect: '% некорректных',
  },
  orderSuspicious: {
    incorrect: 'Некорректные',
    forProcessing: 'В обработку',
    changeStatus: {
      title: 'Смена статуса',
      description: 'Вы уверены, что хотите сменить статус на “{status}” для выбранных заявок?',
    },
  },
  CurrencyConversion: {
    main: 'Основные',
    title: 'Конвертация валют',
    course: 'Курс валют',
    updated: 'Обновлено',
    rub: 'RUB',
    percentIncorrect: '% некорректных',
    at: 'в',
    noCurrenciesData: 'Выберите валюту, для которой необходимо получать курс.',
    converterLink: 'apilayer.com',
    loading: 'Загрузка...',
    baseCurrency: 'Основная валюта системы',
    getCurrencies: 'Получать курс для валют',
    changesSaved: 'Изменения настроек валют сохранены',
    attention: 'Внимание',
    willRecount: 'При смене основной валюты все отчеты будут пересчитаны. ',
    mayErrors:
      'Возможны ошибки пересчета из-за отсутствия необходимых данных о курсе валют в системе.',
    continue: 'Продолжить',
  },
  exportReports: {
    general: 'Общее',
    callCenter: 'Колцентр',
    logistics: 'Логистика',
    finance: 'Финансы',
    redemptionByDay: 'Выкуп по дням',
    reportOrdersCourierDelivery: 'Отчет по заказам с курьерской доставкой',
    hourlyOrdersToday: 'Заказы по часам за сегодня',
    ordersHourSevenDays: 'Заказы по часам за 7 дней',
    notificationReportInactive: 'Отчет по уведомлениям (неактивный)',
    reportRlabNotifications: 'Отчет по RLAB уведомлениям',
    reportCanceledConfirmed: 'Отчет по отмененным подтвержденным',
    repeatCampaignReport: 'Отчет по кампаниям повторных продаж',
    reportSettings: 'Настройки отчета',
    download: 'Скачать',
    selectReport: 'Для настройки выберите отчет',
    applicationsSpecifiedPeriod: 'Заявки за указанный период',
    applicationsSpecifiedPeriodLogistic: 'Заявки за указанный период (список продуктов)',
    period: 'Период',
    writeOffProducts: 'Списание товаров',
    selectFields: 'Выбор необходимых полей',
    fields: {
      offer: 'Оффер',
      salesModel: 'Модель продаж',
      niche: 'Ниша',
      dateCreate: 'Дата/время создания',
      dateApprove: 'Дата/время апрува',
      dateRansom: 'Дата/время выкупа',
      fio: 'ФИО клиента',
      gender: 'Пол клиента',
      age: 'Возраст клиента',
      birthDate: 'Дата рождения клиента',
      firstApproveDate: 'Дата/время первого апрува',
      sendDate: 'Дата отправки',
      comment: 'Комментарий',
      country: 'Страна',
      district: 'Область',
      city: 'Населенный пункт',
      address: 'Адрес',
      postalCode: 'Почтовый код',
      deliveryType: 'Тип доставки',
      deliveryService: 'Служба доставки',
      availableDeliveryServiceType: 'Доступные способы доставки для нас.пункта',
      deliveryPoint: 'Точка доставки',
      deliveryDate: 'Назначенная дата доставки',
      trackNumber: 'Трек номер',
      orderPrice: 'Cумма чека',
      web: 'Веб',
      landing: 'Лендинг',
      salesType: 'Тип продаж',
      status: 'Статус',
      substatus: 'Подстатус',
      mainOperator: 'Оператор апрува',
      productType: 'Тип продуктов',
      product: 'Продукт',
      phone: 'Номер телефона',
      warehouse: 'Склад',
      prepayment: 'Предоплата',
      logs: 'Лог заказа',
      orderId: 'Номер заказа',
      cpaId: 'CPA ID',
      currency: 'Валюта',
      name: 'Имя',
      lastName: 'Фамилия',
      patronymic: 'Отчество',
      street: 'Улица',
      house: 'Номер дома',
      apartment: 'Номер квартиры',
      offerId: 'ID оффера',
    },
    selectStatus: 'Статусы',
    salesModel: 'Модель продаж',
    subStatuses: 'Подстатусы',
    deliveryService: 'Служба доставки',
    userGroups: 'Группы операторов',
    shippedMoreThan: 'Отправлены более 20 дней (1025_1)',
    shippedMoreThanNoBarcode: 'Отправлено более 15 дней без ШК (1025_2)',
    approvedMoreThan14DayCourier: 'Апрув более 14 дней для Курьер (1025_3)',
    approvedMoreThan10Day: 'Апрув более 10 дней для НЕ Курьер (1025_3.2)',
    awaitingRansomMoreThan30Day: 'Ожидает выкуп более 30 дней для Почта (1025_4)',
    awaitingRansomMoreThan15Day: 'Ожидает выкуп более 15 дней кроме Почта (1025_4.2)',
    ordersWithoutBarcode: 'Отчет по заказам без назначенного штрих-кода',
    deliveryIntervalsForDeliver: 'Интервалы доставки для Доставим',
    deliveryPointChanges: 'Изменения точек доставки',
    ordersListWithQuantity: 'CSV__Orders_List_with_quantity',
    reportCod: 'Raport COD',
    productList: 'Product_list',
    summaryPointReport: 'Общий балл КЛН за период',
  },
  smsTemplate: {
    title: 'SMS шаблоны',
    titleForm: 'Шаблон SMS',
    filter: {
      active: 'Активность',
    },
    table: {
      name: 'Название',
      createAt: 'Дата создания',
      author: 'Кто создал',
      actions: 'Действия',
    },
    form: {
      title: 'Настройка шаблона',
      name: 'Название',
      text: 'Текст SMS',
      prev: 'Предпросмотр',
      symbols: 'Символов:',
      sms: 'SMS:',
      info: 'Количество символов для подсчета и тарифицирования SMS в шаблоне, является приблизительным и считается по <br> длине макроса со скобками. Точное количество тарифицируемых SMS сообщает оператор связи',
      isAds: 'Рекламный',
    },
    macros: {
      fname: {
        label: 'Имя_клиента',
        text: 'Александр',
      },
      lname: {
        label: 'Фамилия_клиента',
        text: 'Александров',
      },
      patronymic: {
        label: 'Отчество_клиента',
        text: 'Александрович',
      },
      fullName: {
        label: 'ФИО_клиента',
        text: 'Александров Александр Александрович',
      },
      orderNumber: {
        label: 'Номер_заказа',
        text: '#1123',
      },
      phone: {
        label: 'Телефон',
        text: '+12345678900',
      },
      price: {
        label: 'Цена',
        text: '2500',
      },
      deliveryAddress: {
        label: 'Адрес_ПВЗ',
        text: 'г. Алматы, пр. Абылай Хана 3, Отделение №10',
      },
      tracker: {
        label: 'Трекер',
        text: 'X23ert$wesa',
      },
    },
  },
  workTimeLogType: {
    unavailable: 'Недоступен',
    wait: 'Ожидание',
    offline: 'Оффлайн',
    notAvailable: 'Недоступен',
    autoCall: 'Автообзвон',
    manualCall: 'Звонки вручную',
    ready: 'Готов',
    orderProcessing: 'Обработка заявки',
    personalBreak: 'Перерыв',
    education: 'Обучение',
    kln: 'КЛН',
    consultation: 'Консультация',
    technicalBreak: 'Тех. перерыв',
    pause: 'Не готов',
    done: 'Готов',
    modalInfo:
      'Количество операторов в статусе “Личный перерыв” достигло лимита. Пожалуйста, попробуйте позже.',
    infoErr: 'Лимит пользователей на перерыве превышен',
    technicalBreakShort: 'Тех. перерыв',
    personalBreakShort: 'Перерыв',
  },
  workTime: {
    workTime: 'Учет времени и эффективность',
    operator: 'Оператор',
    workTimeTable: 'Общее время онлайн',
    totalOrder: 'Всего зашло',
    totalOrderTime: 'Обработка Апрува',
    totalApproves: 'Апрувы всего',
    hotApproves: 'Апрувы гор.',
    coldApproves: 'Апрувы хол.',
    efficiency: 'Эффективность',

    timeLimit: 'Вы использовали свое личное время.',
    movedWorkTime: 'Вы перешли в статус “Личный перерыв”.',
    availableBreakTime: 'Доступное время для перерыва',
    imHere: 'Я здесь',
    modalText: 'Вы бездействуете {minutes} мин. Система переведет ваш статус в “Недоступен” через 20 сек.',
    modalInactiveText: 'Ваш статус изменен на «Недоступен». Чтобы перейти в статус «Готов» - нажмите на кнопку «Я здесь» или измените  вручную.',
    modalTitle: 'Внимание',
    filterTitle: 'Тип расчета времени',
    filterType: 'Тип расчета времени',
    reflectionSumNumber: 'Отражение суммы числа',
    reflectionAvg: 'Отражение среднего арифметического числа',
    done: 'Готово',
    applicationProcessing: 'Обработка заявки',
  },
  limitTime: {
    limitTimeTitle: 'Лимиты времени оператора',
    limitTime: 'Лимит времени',
    limitMinutes: 'Ограничения по минутам',
    limitTimePlaceholder: 'Ограничение времени оператора на личный перерыв на рабочий день, мин',
    limitTimeInaction: 'Время разрешенного бездействия оператора, мин',
  },
  district: {
    districts: 'Районы',
    district: 'Район',
    main: 'Основные',
    name: 'Название',
    region: 'Область',
    country: 'Страна',
    actions: 'Действия',
    addDistrict: 'Добавить',
    loadFromFile: 'Загрузить из файла',
  },
  orderTrash: {
    PotentialTrash: 'Потенциальный треш',
    date: 'Дата',
    lastCall: 'Последний набор',
    offers: 'Офферы',
    client: 'Клиент',
    countryLanguage: 'Страна/язык',
    showNumber: 'Показать номер',
    status: 'Статус',
    processedBy: 'Обработал',
    operator: 'Оператор',
    groups: 'Группы',
    comments: 'Комментарий',
    trash: 'Треш',
    dispute: 'Спорный треш',
    trashChecked: 'Обработанный треш',
    trashRaw: 'Необработанный треш',
  },
  ticketStatus: {
    new: 'Новый',
    in_progress: 'В работе',
    fixed: 'Закрыт',
    closed: 'Закрыт',
  },
  logAuth: {
    logAuth: 'Логи логинов/логаутов',
    nameOrEmail: 'ФИО',
    eventName: 'Название события',
    eventTime: 'Время события',
    IpAddress: 'IP адрес',
    country: 'Страна',
    region: 'Регион',
    browser: 'Браузер',
    login_success: 'Логин',
    login_fail: 'Неверный пароль',
    login_logout: 'Логаут',
    watch_number: 'Просмотр номера',
    open_order: 'Открытие заявки',
  },
  logOrder: {
    logOrder: 'Логи заявок',
    date: 'Дата',
    user: 'Пользователь',
    open: 'Открытие',
    close: 'Закрытие',
    duration: 'Длительность',
    offer: 'Оффер',
    phoneNumber: 'Номер телефона',
    status: 'Статус',
    reason: 'Причина',
    sum: 'Сумма',
    locality: 'Населенный пункт',
    deliveryType: 'Тип доставки',
    showNumber: 'Показать номер',
  },
  userPenalty: {
    usersPenalties: 'Штрафы операторов',
    userPenalty: 'Штраф',
    penaltyAmountRu: 'Сумма штрафа',
    penaltyAmountHrn: 'Сумма штрафа (грн)',
    penaltyAmountLe: 'Сумма штрафа (леи)',
    penaltyRu: 'Сумма',
    penaltyUa: 'Штраф(грн)',
    penaltyLe: 'Штраф(лей)',
    description: 'Описание',
    name: 'Название',
    main: 'Основные',
    actions: 'Действия',
  },
  region: {
    regions: 'Области',
    region: 'Область',
    main: 'Основные',
    country: 'Страна',
    timezone: 'Тайм-зона',
    name: 'Название',
    actions: 'Действия',
  },
  additionalOffers: {
    title: 'Связанные офферы кампании',
    currentOffer: 'Текущий оффер',
    salesType: {
      repeated: 'Повторные продажи',
      cancelled: 'Отмены',
      no_ransom: 'Возврат',
      certificate: 'Сертификаты',
    },
  },
  relatedOffers: {
    title: 'Связанные офферы',
  },
  questionnaires: {
    questionnaires: 'Анкеты',
    name: 'Название',
    actions: 'Действия',
    createQuestionnaires: 'Анкета',
    general: 'Общее',
    questions: 'Вопросы',
    main: 'Основные',
    country: 'Страна',
    salesType: 'Тип продаж',
    language: 'Язык',
    subtitle: 'Чтобы перейти на следующий таб, заполните форму и сохраните страницу.',
    dependent: 'Зависимый',
    addAnswer: 'Добавить ответ',
    deleteAnswer: 'Удалить вопрос',
    answer: 'Вопрос',
    answerName: 'Имя',
    add: 'Добавить',
    openAnswer: 'Открытый ответ',
    filterCountries: 'Cтраны',
    filterOffers: 'Офферы',
    filterGroupOffers: 'Группы офферов',
    filterTags: 'Теги',
    filterOperators: 'Операторы',
    filterOperatorsGroup: 'Группы операторов',
    filterSalesType: 'Тип продаж',
    hotOperator: 'Оператор горячей',
    questionnaireOperator: 'Оператор анкетирования',
    offer: 'Оффер',
    result: 'Результат',
    operator: 'Оператор анкетированя',
    group: 'Группы',
    questionnaire: 'Анкетирование',
    numberOfRecords: 'Количество записей',
    dependOnAnswer: 'от ответа',
    dependOnAnswerQuestion: 'на вопрос',
    applicationForm: 'Заявка анкеты',
    dateHot: 'Дата горячей',
    hotGroupOperator: 'Группа оператора горячей',
    date: 'Дата анкетирования',
    inputQuestion: 'Введите вопрос',
    errorMessage:
      'Вопрос должен иметь как минимум один вариант ответа. Добавьте ответ либо удалите вопрос',
    duplicateHeader: 'Введите название',
    operatorGroups: 'Группы операторов',
  },
  organization: {
    name: 'Название',
    activity: 'Активность',
    syncWith1C: 'Синхронизировать с 1С',
  },
  operatorGrade: {
    title: 'Грейды операторов',
    saleType: {
      hot: 'Горячие',
      repeated: 'Повторные',
    },
    operator: 'Оператор',
    saleTypeTitle: 'Направление продаж',
    workStartDate: 'Трудоустроен (мес)',
    groups: 'Группа',
    approvePercentOvp: '% aпрува',
    ransomPercentOvp: '% выкупа',
    approveCheckOvp: 'Средний чек апрува',
    ransomCheckOvp: 'Средний чек выкупа',
    ransomCheckOvpAndRansomPercentOvp: 'Средний чек и % выкупа',
    workTime: 'Время онлайн',
    efficiency: 'Эффективность',
    kln: 'КЛН',
    recommendedGrade: 'Соответствие грейду',
    grade: 'Грейд',
    KpiNorms: 'Нормы KPI',
    recountGrades: 'Пересчет грейдов',
    lastRecount: 'Последний пересчет:',
    recountNotifTitle: 'Пересчет рекомендуемых грейдов',
    recountNotifDescription:
      'Грейды пересчитываются в фоновом режиме. Вы получите уведомление, когда он будет завершен.',
    normsKpiForGrade: 'Нормы KPI для повышения грейда',
    hot: 'Горячие продажи',
    resales: 'Повторные продажи',
    condition: 'Условия',
    intern: 'Стажер',
    manager: 'Менеджер',
    specialist: 'Специалист',
    professional: 'Профиссионал',
    master: 'Мастер',
    workTimeTable: 'Рабочее время',
    effectiveness: 'Эффективность',
    checkAndRansomPercent: 'ОВП по чеку и % выкупа',
    checkAndApprove: 'ОВП по чеку аппрува',
    checkAndRansom: 'ОВП по чеку выкупа',
    checkAndApprovePercent: 'ОВП по % аппрува',
    ransomCheck: 'ОВП по % выкупа',
    knlScores: 'Баллы КЛН',
    error: 'Ошибка вычисления',
    errorGrade: 'Невозможно определить грейд',
  },
  cityImport: {
    addFile: 'Добавить файл',
    loadFromFile: 'Загрузить из файла',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    country: 'Страна',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    importCity: 'Импорт населенных пунктов',
    field: {
      region: 'Область',
      district: 'Район',
      city: 'Населенный пункт',
      deliveryType: 'Тип доставки',
      deliveryService: 'Служба доставки (тип)',
      delivery_service: 'Служба доставки (тип)',
      warehouse: 'Склад',
      errors: 'Информация',
      name: 'Населенный пункт',
      serviceName: 'Имя в сервисе',
      connectService: 'Подключить СД',
    },
    tooltip: 'Найдено два или более нас.пункта с дублирующим названием.',
  },
  delivery: {
    deliveryCourier: 'Курьерская доставка',
    deliveryPoints: 'Пункт выдачи',
    deliveryPost: 'Почта',
    list: {
      bareCode: 'Штрихкод',
      orderList: 'Состав заказа',
      productsAmount: 'Кол-во продуктов',
      dateApprove: 'Дата апрува',
      deliveryWaiting: 'Ожидает отправки',
      deliveryDate: 'Дата доставки',
      city: 'Нас.пункт',
      district: 'Район',
      region: 'Область',
      country: 'Страна',
      catalog: 'Каталог',
      terminalPayment: 'Терминал',
      dateToDeliveryService: 'Дата отправки в сервис',
      comments: 'Комментарий',
      deliveryServiceAnswer: 'Ответ СД',
      offers: 'Оффер',
      postAddress: 'Адресс',
      operator: 'Оператор',
    },
    filter: {
      countries: 'Страны',
      regions: 'Области',
      districts: 'Районы',
      cities: 'Нас.пункты',
      offer: 'Офферы',
      groupOffers: 'Группы офферов',
      operator: 'Операторы',
      terminalPayment: 'Наличие терминала',
      tags: 'Теги',
    },
  },
  smsCampaign: {
    title: 'SMS кампании',
    table: {
      id: 'ID',
      name: 'Название',
      template: 'Шаблон',
      sender: 'Имя отправителя',
      orderStatus: 'Статус заявки',
      dayInStatus: 'Дней в статусе',
      active: 'Статус кампании',
    },
    filter: {
      date: 'Дата',
      active: 'Активность',
      deliveryServices: 'Cлужба доставки',
      offers: 'Офферы',
      country: 'Cтраны',
      templates: 'Шаблоны',
      nameSender: 'Имя отправителя',
    },
    form: {
      title: 'SMS кампания',
      titleManual: 'SMS кампания ручная',
      titleItem: 'Настройки кампании',
      titleItemManual: 'Основные',
      titleMain: 'Основное',
      name: 'Название',
      template: 'Шаблон',
      sender: 'Имя отправителя',
      country: 'Страна',
      delivery: 'Служба доставки',
      active: 'Статус кампании',
      offers: 'Офферы',
      orderStatus: 'Статус заявки',
      startDate: 'Дата присвоения статуса с',
      startDateManual: 'Дата старта кампании',
      endDate: 'Дата присвоения статуса по',
      dayInStatus: 'Дней в статусе',
      service: 'Служба доставки',
      operatorSms: 'Оператор SMS',
    },
    automatically: 'Автоматически',
  },
  smsList: {
    title: 'Отчет SMS кампаний',
    id: 'ID Заявки',
    status: 'SMS',
    smsCount: 'Кол-во SMS',
    sendDate: 'Дата/время',
    smsStatus: 'Статусы SMS',
    getStatus: 'Запросить статус',
    sendMessage: 'Сообщение доставлено',
  },
  smsStatuses: {
    sent: 'Отправлено',
    pending: 'Ожидается',
    error: 'Ошибка',
  },
  orderStatuses: {
    accepted: 'Новый заказ',
    callback: 'Колбек',
    approved: 'Апрув',
    cancelled: 'Отмена',
    shipped: 'В дороге',
    delivered: 'Доставлен',
    no_ransom: 'Возврат',
    packaging: 'Упаковка',
    lost_package: 'Посылка утеряна',
    // старые статусы ниже
    processing: 'Обработка',
    incorrect: 'Некорректный',
    canceled: 'Отмена',
    awaiting_ransom: 'Ожидает выкуп',
    call: 'Звонок',
    trash: 'Треш',
    paid: 'Оплачено',
    partially_paid: 'Частично оплачено',
    chooseCols: 'Выбрать колонки',
    suspicious: 'Подозрительная',
    all: 'Все',
  },
  callStatuses: {
    dialing: 'Набор',
    call: 'Звонок',
    success: 'Дозвон',
    failed_attempt: 'Недозвон',
    failed: 'Финальный недозвон',
  },
  evaluationOptions: {
    yes: 'Дa',
    no: 'Нет',
    partial: 'Частично',
    dont_consider: 'Не учитывать',
  },
  offerNorms: {
    offer: 'Оффер',
    averageCheck: 'Средний чек апрува',
    approve: 'Апрув',
    ransomCheck: 'Чек выкупа',
    ransom: 'В дороге',
    top: 'Топ',
    avg: 'Среднее',
    mediana: 'Медиана',
    norma: 'Норма',
  },
  salesScriptCopy: {
    title: 'Введите название',
    copy: 'Копия - ',
  },
  salesModel: {
    salesModel: 'Модель продаж',
    newSalesModel: 'Добавить',
    salesModelAdd: 'Добавить модель продаж',
    salesModelEdit: 'Редактировать модель продаж',
    name: 'Название',
    salesType: 'Тип продаж',
    slug: 'Название латиницей',
  },
  managerDashboard: {
    title: 'Дашборд',
    ordersChartTitle: 'Заказы',
    hangingRansomChartTitle: 'Зависший выкуп (по отправке)',
    deliveryServiceOrderChartTitle: 'Отправки, выкупы и оплаты по СД',
    approvesPensionerChartTitle: 'Процент апрувов 55+',
    deliveryServiceDebtChartTitle: 'Долг по оплатам СД',
    approvesByTimeChartTitle: 'Апрувы по времени суток',
    amountAndAverageChartTitle: 'Количество и среднее время заявок в статусе',
    noRansom72HoursTitle: 'Отмена/невыкуп 72ч. после апрува',
    totalCancelAfterApprove: 'Всего отмена после апрува',
    cancel72hours: 'Отмена 72 ч.',
    noRansom72hours: 'Невыкуп 72 ч.',
    allApprovePerDay: 'Всего апрувов за день',
    approvePerDay: 'Апрувы 55+ за день',
    orderPerDay: 'Всего поступило заказов за день',
    approvesPerDay: 'Всего апрувов за день',
    approves: 'Апрувы',
    avgTimeToApprove: 'Среднее время от захода до апрува',
    send: 'Отправлено',
    amount: 'Количество',
    redeemed: 'Выкуплено',
    paid: 'Оплачено',
    widgets: 'Виджеты',
    overThePast: 'последние 30 дней',
    status: 'Статус',
    count: 'Кол-во',
    time: 'Время',
    sum: 'Сумма',
    groupOperators: 'Группа операторов',
    salesType: 'Тип продаж',
    deliveryType: 'Способ доставки',
    deliveryService: 'Служба доставки',
    statusHangingRansom: {
      suspicious: 'Подозрительный',
      shipped: 'Отправлено',
      processing: 'Обработка',
      approved: 'Апрув',
      canceled: 'Отмена',
      accepted: 'Новый заказ',
      callback: 'Колбек',
      awaiting_ransom: 'Ожидает выдачи',
      packaging: 'Упаковка',
      ransom: 'В дороге',
      no_ransom: 'Возврат',
      debt: 'Долг СД',
      delivered: 'Выкуп',
      partially_paid: 'Част. оплачено',
      paid: 'Оплачено',
      incorrect: 'Некорректный',
      trash: 'Треш',
      total: 'Всего',
    },
    dates: {
      shortDays: 'д.',
      shortHours: 'ч.',
      shortMinutes: 'м.',
      shortSeconds: 'с.',
    },
  },
  salaryBonus: {
    salaryBonus: 'Матрица для осн. ЗП КЦ',
    country: 'Страна',
    operatorGrade: 'Грейд оператора',
    salesModel: 'Модель продаж',
    checkCurrency: 'Валюта чека',
    checkFrom: 'Чек от',
    checkUp: 'Чек до',
    basePayOperator: 'Базовая оплата оператору',
    exportFileName: 'Шаблон импорта ЗП',
    additionalPaymentForGradeOperator: 'Доплата за грейд оператору',
    additionalPaymentForCheckOperator: 'Доплата за чек оператору',
    basicPaymentForTeamLeadTeamLead: 'Базовая оплата тимлиду',
    additionalPaymentForGradeTeamLead: 'Доплата за грейд тимлиду',
    additionalPaymentForCheckTeamLead: 'Доплата тимлиду за чек',
    totalPaymentOperator: 'Итого оплата оператору',
    totalPaymentTeamLead: 'Итого оплата тимлиду',
    filterCountry: 'Страны',
    filterSalesModel: 'Модели продаж',
    filterOperatorGrade: 'Грейды оператора',
    import: 'Импорт данных для ЗП КЦ',
    infoImport:
      'Скачайте шаблон для заполнения данных, заполните и загрузите.<br/>Загружаемый файл полностью перезапишет данные в системе.',
    importData: 'Импортировать ',
    exportTable: 'Скачать таблицу для редактирования можно здесь',
    modalImport:
      'Будут импортированы только те строки, которые не содержат<br/> ошибок. Данные будут применены при ближайшем расчете ЗП.',
    warningText: 'Загружаемый файл полностью перезапишет<br/>данные в системе.',
    field: {
      amountFrom: 'Чек от',
      amountTo: 'Чек до',
      baseOperatorAmount: 'Базовая оплата оператору',
      baseTeamLeaderAmount: 'Базовая оплата тимлиду',
      country: 'Страна',
      grade: 'Грейд оператора',
      operatorCheckSurcharge: 'Доплата за чек оператору',
      operatorGradeSurcharge: 'Доплата за грейд оператору',
      salesModel: 'Модель продаж',
      teamLeaderCheckSurcharge: 'Доплата тимлиду за чек',
      teamLeaderGradeSurcharge: 'Доплата за грейд тимлиду',
      errors: 'Информация',
    },
  },
  dashboardNotifications: {
    notifications: 'Уведомления',
    clear: 'Очистить',
    unread: 'Непрочитанные',
    read: 'Прочитанные',
    listIsEmpty: 'Список уведомлений пуст',
    loading: 'Загрузка уведомлений',
  },
  notifications: {
    operator_not_ready_for_callback: 'Неготовность оператора',
    duty_manager_absent: 'Дежурный менеджер',
    personal_break_time_limit: 'Лимит личного перерыва',
    personal_break_time_left: 'Осталось времени перерыва',
    order_manager_help: 'Запрос помощи оператору',
    product_deactivated: 'Деактивация продукта',
    mng_handing_ransom: 'Задержка выплат от СД',
    grade_recount_finish: 'Пересчёт грейдов операторов',
    currency_synchronize_error: 'Ошибка обновления',
    metaship_synchronize_error: 'Ошибка обновления',
  },
  prepayment: {
    title: 'Аванс 1',
    cardNumber: 'Номер карты',
    fullName: 'Фамилия, имя, отчество',
    amount: 'Сумма',
    comment: 'Комментарий',
    average: 'Среднее значение',
    total: 'Итого',
    supervisor: 'Руководитель',
    userGroups: 'Группа пользователей',
    titleTwo: 'Аванс 2',
    fileName1: 'аванс1_операторов_',
    fileName2: 'аванс2_операторов_',
    fileNameTeamLead1: 'аванс1_тимлидов_',
    fileNameTeamLead2: 'аванс2_тимлидов_',
    export: 'Экспорт',
  },
  additionalProjects: {
    title: 'Эффективность доп. проектов',
    hot: 'Горячие',
    correct: 'Корректных',
    approve: 'Апрув',
    ransom: 'В дороге',
    efficiencyAdditionalProjects: 'Эф. доп. проектов',
    catalog: 'Каталог',
    rub: '(руб)',
    catalogs: 'Каталоги',
    repeated: 'Повторные',
    cancellation: 'Отмены',
    nonRedemption: 'Невыкуп',
    income: 'Доход',

    filterOffers: 'Офферы',
    filterOfferGroup: 'Группы офферов',
    filterWeb: 'Вебы',

    offer: 'Оффер',
    web: 'Веб',
  },
  operatorDashboard: {
    applications: 'Заявки',
    topOperatorsTooltip: 'Топ операторов по подтвержденным заказам',
    dailyEarningTooltip: 'Данные обновляются каждый час',
    currentGrade: 'Текущий грейд',
    dailyEarning: 'Доход за день',
    schedule: 'График работы',
    approve: 'апрува',
    earningTable: {
      online: 'Онлайн',
      approves: 'Апрувы',
      approve: 'Апрув',
      ransoms: 'Выкупы',
      ransom: 'В дороге',
      payment: 'Начислено',
      bonuses: 'Начислено бонусов',
      penalty: 'Штрафы',
      hanging: 'Удержания',
      profit: 'Премии/начисления',
      hours: 'часов',
      uah: 'грн',
      eur: 'евро',
    },
    statusHangingRansom: {
      approved: 'Апрув',
      canceled: 'Отмена',
      delivered: 'Выкуп',
      paid: 'Оплачено',
      packaging: 'Упаковка',
      partially_paid: 'Част. оплачено',
      awaiting_ransom: 'Ожидает выкуп',
      ransom: 'В дороге',
      no_ransom: 'Возврат',
      shipped: 'Отправлено',
      callback: 'Колбек',
      debt: 'Долг СД',
      accepted: 'Новый заказ',
      processing: 'Обработка',
      incorrect: 'Некорректный',
      trash: 'Треш',
      total: 'Всего',
    },
    noAvailableData: 'Нет данных',
    hotTop: 'Топ горячие',
    top: 'Топ операторов',
    ratingError: 'Ошибка расчета рейтинга',
    repeatedTop: 'Топ повторные',
    yourRating: 'Ваш рейтинг',
    dayType: 'Тип дня',
    reason: 'Причина',
    scheduleWork: 'График',
    changeDate: 'Изменить',
    scheduleSub: {
      work_day: 'Рабочий день',
      day_off: 'Выходной',
      vacation: 'Отпуск',
      sick_leave: 'Больничный',
      session: 'Сессия',
      absent: 'Невыход',
    },
  },
  calendar: {
    months: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
  },
  klnStatistic: {
    evaluationPoints: 'Пункты оценки',
    noDataMessage: 'Недостаточно данных для отображения',
    groupsInformation: 'Информация по группам',
    moreInfo: 'Подробнее',
    selectAll: 'Выбрать все',
    clear: 'Очистить',
    filter: {
      groupOperators: 'Группы операторов',
      operators: 'Операторы',
      offerGroups: 'Группы офферов',
      offers: 'Офферы',
      niches: 'Ниши',
      salesTypes: 'Типы продаж',
      salesModels: 'Модели продаж',
      country: 'Страна',
      regions: 'Области',
      deliveryTypes: 'Типы доставки',
      deliveryServices: 'Cлужбы доставки',
    },
  },
  klnAssessment: {
    title: 'Оценки КЛН',
    titleOperator: 'КЛН оператора',
    export: 'Экспорт',
    orderNumber: 'Заказ №',
    status: 'Статус',
    niches: 'Ниша',
    offer: 'Оффер',
    operator: 'Оператор',
    managerKln: 'Менеджер КЛН',
    dateValue: 'Дата оценивания',
    updatedAt: 'Дата обновления',
    operatorsGroup: 'Группы опереторов',
    total: 'Всего',
    workStatus: {
      created: 'Не обработано',
      pending: 'В работе',
      finished: 'Обработано',
    },
    addAudio: 'Добавить запись',
    listAudio: 'Список аудио',
    descInfoModal:
      'Заявка в статусе “В работе”. Возможно, ее редактирует другой сотрудник. При нажатии на “Сохранить” данные будут перезаписаны.',
    callTimeItems: {
      low: 'до 4 мин',
      middle: '6-10 мин',
      top: '10+ мин',
    },
    callTime: 'Длительность разговора',
    speed: 'Скорость х2',
  },
  webAnalytica: {
    title: 'Выплаты вебам',
    date: 'Дата',
    web: 'Веб',
    source: 'Источник',
    order: 'Заказ',
    offer: 'Оффер',
    typeProduct: 'Тип продукта',
    bid: 'Ставка',
    country: 'Страна',
  },
  questionnairesStatistics: {
    statistics: 'Статистика',
    form: 'Анкета',
    period: 'Период',
    btnConfirm: 'К заказу',
    title: 'Анкета от',
    titleTwo: 'по заявке',
    questionAnswer: 'Вопрос/Ответ',
    hotOperator: 'Оператор горячей',
    operator: 'Оператор анкеты',
    export: 'Экспорт',
    back: 'К списку',
    total: 'Всего',
  },
  paymentHold: {
    paymentHold: 'Удержание',
    paymentHolds: 'Удержания',
    main: 'Основные',
    name: 'Название',
    amount: 'Сумма',
    description: 'Описание',
    penaltyRu: 'Сумма',
    comment: 'Описание',
    actions: 'Действия',
  },
  paymentProfit: {
    paymentProfit: 'Начисление',
    paymentProfits: 'Начисления',
    main: 'Основные',
    name: 'Название',
    amount: 'Сумма',
    description: 'Описание',
    comment: 'Описание',
    actions: 'Действия',
  },
  modalSuccessError: {
    close: 'Закрыть',
    success: 'Успех',
    error: 'Ошибка',
    successSync: 'Синхронизация успешно завершена',
    errorSync: 'Во время операции произошла ошибка синхронизации',
  },
  notFound: {
    title: 'Ой, произошла ошибка...',
    text: 'Страница не существует или у вас нет прав доступа к ней.',
    btnText: 'На главную',
  },
  regionImport: {
    regionImport: 'Импорт областей',
    country: 'Страна',
    preview: 'Предпросмотр',
    importData: ' Импортировать данные',
    addFile: 'Добавить файл',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    field: {
      name: 'Область',
      timezone: 'Таймзона',
      errors: 'Информация',
    },
  },
  districtImport: {
    districtImport: 'Импорт районов',
    country: 'Страна',
    preview: 'Предпросмотр',
    importData: ' Импортировать данные',
    addFile: 'Добавить файл',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    field: {
      region: 'Область',
      name: 'Район',
      errors: 'Информация',
    },
  },
  hangingRansom: {
    title: 'Зависший выкуп',
    day: 'День',
    totalRansom: 'Всего выкуплено',
    sum: 'Сумма',
    paid: 'Оплачено',
    percent: '%',
    partPaid: 'Частично оплачено',
    sumCheck: 'Сумма к оплате',
    sumPaid: 'Сумма оплачено',
    waitPaid: 'Ожидает оплаты',
    countOrderCredit: 'Кол-во заказов с долгом',
    creditCd: 'Долг СД',
    total: 'Итого',
    offer: 'Оффер',
    statusChart: {
      paid: 'Оплачено:',
      waitPaid: 'Ожидает опл:',
      partPaid: 'Частично опл:',
      deptCd: 'Долг СД:',
      total: 'Всего выкуп:',
    },
    filters: {
      offer: 'Оффер',
      groupOffer: 'Группа офферов',
      tags: ' Теги',
      offerNiche: 'Ниша офферов',
      salesModel: 'Модель продаж',
      deliveryService: 'Служба доставки',
      deliveryType: 'Способ доставки',
      countries: 'Страна',
      regions: 'Область',
      districts: 'Район',
      cities: 'Населенный пункт',
      userGroups: 'Группа пользователей',
      web: 'Веб',
      questionnaires: 'Анкета повторных',
    },
    filtersSelect: {
      month: 'Месяц',
      country: 'Cтрана',
      region: 'Область',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      delivery_service: 'Cлужба доставки',
      sales_model: 'Модель продаж',
      offer: 'Офферы',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approve: 'По апруву',
      shipped: 'По отправке ',
      delivered: 'По выкупу',
    },
    count: 'Кол-во',
    amountOfDebt: 'Сумма долга',
    unpaidOrders: {
      title: 'Заказы с зависшим выкупом',
      btn: 'Список заказов',
      orderStatus: 'Статус заказа',
    },
  },
  averageCheck: {
    title: 'Средние чеки',
    hours: 'Час',
    day: 'День',
    orders: 'Заказов',
    total: 'Всего',
    correct: 'Корректных',
    wasCall: 'Был звонок',
    confirmed: 'Подтверждено',
    counts: 'Кол-во',
    percentCorrect: '% от Корректных',
    percentTotal: '% от Всего',
    totalMinutes: 'Время (c.)',
    check: 'Чек',
    sent: 'Отправлено',
    noRansom: 'Невыкупы (возврат)',
    percentConfirmed: '% от Подтвер.',
    redeemedAverageCheck: 'Выкуплено',
    percentSent: '% от Отправл.',
    male: 'Мужской',
    female: 'Женский',
    filters: {
      countries: 'Страны',
      regions: 'Области',
      city: 'Населенные пункты',
      deliveryService: 'Службы доставки',
      offers: 'Офферы',
      offersGroup: 'Группы офферов',
      offersNiche: 'Ниши офферов',
      operators: 'Операторы',
      landings: 'Лендинги',
      webs: 'Вебы',
      salesModel: 'Модели продаж',
      productType: 'Типы продуктов (Оффер)',
      operatorGroups: 'Группы пользователей',
      age: 'Возраст',
      gender: 'Пол',
    },
    select: {
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      processing_date: 'Дата обработки',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      countries: 'Страна',
      region: 'Область',
      city: 'Населенный пункт',
      operator_group: 'Группа операторов',
      operator: 'Оператор',
      landing: 'Лендинг',
      web: 'Веб',
      reject_reason: 'Причина отмены',
      delivery_service: 'Служба доставки',
      sales_model: 'Модель продаж',
      product_type: 'Тип продукта (СРМ)',
      age: 'Возраст',
      gender: 'Пол',
      warehouse: 'Склад отправки',
      order_check: 'Чек',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      processingDate: 'По обработке',
      approveDate: 'По апруву',
      shippedDate: 'По отправке',
      ransomDate: 'По выкупу',
    },
    null: '-',
  },
  deliveryExcludedRules: {
    main: 'Основные',
    name: 'Название',
    country: 'Страна',
    countries: 'Страны',
    offers: 'Офферы',
    regions: 'Области, для которых исключаются СД',
    deliveryServices: 'Исключенные службы доставки',
    list: {
      deliveryServices: 'Исключенные СД',
      regions: 'Области',
    },
  },
  legalPersonsStats: {
    title: 'Статистика по юр. лицам',
    countries: 'Страны',
    regions: 'Области',
    deliveryTypes: 'Способы доставки',
    levelTypes: {
      warehouse: 'Склад отправки',
      organization: 'Юр. лицо',
      deliveryService: 'Служба доставки',
      deliveryType: 'Способ доставки',
      offer: 'Оффер',
      product: 'Продукт',
      country: 'Страна',
      region: 'Область',
    },
    sentVolume: 'Отправлено (объем)',
    volumePercent: '% объема',
    volumePercentTotal: '% объема общий',
    dateOfFirstShipment: 'Дата первой отправки',
    dateOfLastShipment: 'Дата последней отправки',
    averageCheck: 'Ср. чек',
    total: 'Всего отправлено заказов',
  },
  deliveryServiceAnalytic: {
    title: 'Аналитика служб доставки',
    regions: 'Области',
    redeemed: 'Выкуплено',
    sent: 'Отправлено',
    percent: '%',
    select: {
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      country: 'Страна',
      region: 'Область',
      city: 'Населенный пункт',
      user_group: 'Группа операторов',
      operator: 'Оператор',
      landing: 'Лендинг',
      web: 'Веб',
      delivery_service: 'Служба доставки',
      sales_model: 'Модель продаж',
      product_type: 'Тип продукта (СРМ)',
      warehouse: 'Склад отправки',
    },
    isActiveCity: 'Статусы нас-ых пунктов',
    groupDateBy: {
      year: 'Год',
      quarter: 'Квартал',
      month: 'Месяц',
      week: 'Неделя',
      day: 'День',
    },
    totalType: {
      totalAll: 'За весь возможный период',
      totalCurrent: 'За выбранный период в фильтрах',
    },
    filters: {
      deliveryServices: 'Службы доставки',
      countries: 'Страны',
      regions: 'Области',
      districts: 'Районы',
      cities: 'Населенные пункты',
      deliveryTypes: 'Способы доставки',
      warehouses: 'Склады',
      checkFrom: 'Чек заказа от',
      checkTo: 'Чек заказа до',
      offers: 'Офферы',
      products: 'Продукты',
      groupDate: 'Группировка по периоду',
      totalType: 'Расчет итога',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approveDate: 'По апруву',
      firstApproveDate: 'По дате первого апрува',
      shippedDate: 'По отправке',
      deliveryDate: 'По доставке',
      ransomDate: 'По выкупу',
      lastStatus: 'По дате конечного статуса',
    },
    totalAll: 'Итог за весь возможный период',
    shippedOrders: 'Отгружено заказов',
    ransom: 'В дороге',
    activate: 'Активировать',
    deactivated: 'Деактивировать',
  },
  deliveryPointsImport: {
    title: 'Импорт точек доставки',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    downloadTemplateYouCanHere: 'Скачать шаблон можно здесь',
    addFile: 'Добавить файл',
    labels: {
      country: 'Страна',
      status: 'Статус',
    },
    field: {
      name: 'Название',
      city: 'Населенный пункт',
      region: 'Область',
      district: 'Район',
      deliveryService: 'Служба доставки',
      address: 'Адрес',
      phone: 'Телефон',
      integrationKey: 'Ключ интеграции',
      minDays: 'Мин. дней',
      maxDays: 'Макс. дней',
      comment: 'Комментарий',
      workTime: 'Время работы',
      terminal: 'Терминал',
      errors: 'Информация',
    },
  },
  welcomePage: {
    singleSolution: 'Единое решение для:',
    callCentre: 'Колл-центра',
    logistics: 'Логистики',
    management: 'Менеджмента',
    noticeDepartment: 'Уведомлений',
  },
  distributionCheck: {
    title: 'Распределение чеков',
    totalApprove: 'Всего апрувов',
    filters: {
      offer: 'Оффер',
      offerGroups: 'Группа офферов',
      niche: 'Ниша',
      web: 'Веб',
      landings: 'Лендинг',
      salesModel: 'Модель продаж',
      salesType: 'Тип продаж',
      operators: 'Оператор',
      userGroups: 'Группа пользователей',
      country: 'Страна',
      region: 'Область',
    },
  },
  userSalary: {
    title: 'ЗП операторов',
    total: 'Итого',
    cardNumber: 'Номер карты',
    fullName: 'Фамилия, имя, отчество',
    amount: 'Сумма',
    comment: 'Комментарий',
    supervisor: 'Руководитель',
    userGroups: 'Группа пользователей',
    fileName: 'ЗП операторов',
    currency: 'Валюта оператора',
  },
  buybackDynamics: {
    title: 'Динамика выкупа по дням',
    total: 'Итого',
    table: {
      dayDifference: 'Разница дней',
      redeemed: 'Выкуплено',
      redeemedPercent: '% выкуплено',
      sent: 'Отправлено',
      notRedeemed: 'Не выкуплено',
      redemptionVolume: 'Объем выкупаемости',
    },
    filters: {
      deliveryServices: 'Службы доставки',
      countries: 'Страны',
      regions: 'Области',
      deliveryTypes: 'Способы доставки',
      warehouses: 'Склады',
      checkFrom: 'Чек заказа от',
      checkTo: 'Чек заказа до',
      orderId: 'Id заказа',
      callCount: 'Количество звонков',
      offers: 'Офферы',
      products: 'Продукты',
      tags: 'Теги',
    },
    filterDateBy: {
      created_at: 'По заходу',
      approve: 'По апруву',
      first_approve: 'По дате первого апрува',
      shipped: 'По отправке',
      awaiting_ransom: 'По “Ожидается выкуп”',
    },
  },
  reportWithoutBarcode: {
    city: 'Населенный пункт',
    downloadFileName: 'Выгрузка без ШК',
  },
  callController: {
    title: 'Звонки',
    id: 'ID',
    operator: 'Оператор',
    orderId: 'ID заказа',
    status: 'Статус звонка',
    showNumber: 'Показать номер',
    startOfCall: 'Начало звонка',
    duration: 'Продолжительность',
    callRecord: 'Запись звонка',
    date: 'Дата',
    time: 'Время',
    order: 'Заявка',
    offer: 'Оффер',
    type: 'Тип',
    types: {
      auto: 'Авто набор',
      manual: 'Ручной набор',
    },
    filter: {
      operatorGroup: 'Группа операторов',
    },
    resultCall: 'Результат звонка',
  },
  rejectionStatistics: {
    title: 'Статистика отклоненных',
    table: {
      day: 'День',
      total: 'Всего',
      rejected: 'Отклоненные',
    },
    total: 'Итого',
    filtersGroup: {
      status_group: 'Статус',
      reject_reason: 'Причина статуса',
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      operator_groups: 'Группа операторов',
      country: 'Страна',
      web: 'Веб',
      operator: 'Оператор',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
    },
    filterDateBy: {
      createdAt: 'по заходу',
      approve: 'по апруву',
      processing: 'по обработке',
    },
    filters: {
      groupOperators: 'Группы операторов',
      operators: 'Операторы',
      groupOffers: 'Группы офферов',
      offers: 'Офферы',
      niches: 'Ниши',
      salesTypes: 'Типы продаж',
      salesModel: 'Модели продаж',
      countries: 'Страны',
      regions: 'Области',
      deliveryTypes: 'Способы доставки',
      deliveryServices: 'Службы доставки',
    },
    statuses: {
      canceled: 'Отмена',
      callback: 'Колбек',
      trash: 'Треш',
    },
  },
  callCentreStatistics: {
    title: 'Статистика КЦ',
    table: {
      day: 'День',
      allOrders: 'Все заказы',
      approve: 'Апрув',
      approveFromAll: 'Апрув от всех',
      approveFromCorrect: 'Апрув от корректных',
      ransom: 'В дороге',
      delivered: 'Доставлено',
      productTypes: 'Типы товаров',
      processing: 'Обработка',
      indicators: 'Целевые показатели',
      targetCheck: 'Целевой чек',
      targetApprove: 'Целевой апрув',
      approveCheck: 'Чек апрува',
      targetRansomPercent: 'Целевой процент выкупа',
      delivery: 'Доставка',
      total: 'Всего',
      totalOrder: 'Всего зашло',
      correct: 'Корректных',
      processed: 'Обработано',
      salesAmountPerHour: 'К-во продаж в час',
      call: 'Дозвон',
      avgCheck: 'Ср. чек',
      avgOrderTime: 'Ср. время обработки заявки',
      avgTime: 'Ср. время',
      fiftyFive: '55+',
      confirmCPA: 'Подтв. СРА',
      basic: 'Основной',
      substitute: 'Заменитель',
      alternative: 'Альтернативный',
      cross: 'Кросс товар',
      gift: 'Подарок',
      coupon: 'Купон',
      additionallyCross: 'Доп. кросс',
      another: 'Другой',
      calling: 'В обзвоне',
      callback: 'Колбек',
      trash: 'Треш',
      cancel: 'Отмены',
      removeCall: 'Снятые с прозв.',
      deliveryOn: 'Доставка включена',
      countSales: 'Выкуп',
      processingTime: 'Время обработки'
    },
    select: {
      hour: 'Час',
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      country: 'Страна',
      region: 'Область',
      city: 'Населенный пункт',
      operator_groups: 'Группа операторов',
      operator: 'Оператор',
      landing: 'Лендинг',
      web: 'Веб',
      reject_reason: 'Причина статуса',
      delivery_service: 'Служба доставки',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
      product_type: 'Тип продукта (СРМ)',
      age: 'Возраст',
      gender: 'Пол',
      product: 'Продукт',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approve: 'По апруву',
      processing: 'По обработке',
      shipped: 'По отправке ',
      delivered: 'По выкупу',
    },
    filters: {
      offerGroups: 'Группы офферов',
      offers: 'Оффер',
      products: 'Продукты',
      tags: 'Теги',
      offerNiche: 'Ниша офферов',
      salesModel: 'Модель продаж',
      deliveryServices: 'Служба доставки',
      deliveryType: 'Способ доставки',
      country: 'Страна',
      region: 'Область',
      city: 'Населенный пункт',
      userGroups: 'Группа пользователей',
      operators: 'Операторы',
      web: 'Веб',
      landing: 'Лендинг',
      salesType: 'Тип продаж',
      gender: 'Пол',
      questionnaires: 'Анкета повторных',
    },
    gender: {
      male: 'Мужской',
      female: 'Женский',
    },
  },
  suspiciousOrders: {
    title: 'Подозрительные заявки',
  },
  reconfirmationStatistic: {
    title: 'Повторные подтверждения',
    select: {
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      operator: 'Оператор',
      operator_groups: 'Группа операторов',
      delivery_service: 'Служба доставки',
      delivery_type: 'Способ доставки',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
      age: 'Возраст',
      gender: 'Пол',
    },
    table: {
      total: 'Поступило в ПП',
      call: 'Дозвон',
      approve: 'Подтверждено',
      cancel: 'Отменено',
      shipped: 'Дозвон отправлено',
      awaitingRansom: 'Дозвон ожидает выдачи',
      ransom: 'Выкуп с подтвержденных',
      noRansom: 'Невыкуп с подтвержденных',
      failedCall: 'Недозвон',
      failedCallShipped: 'Недозвон отправлено',
      failedCallAwaitingRansom: 'Недозвон ожидает выдачи',
      failedCallRansom: 'Выкуп с недозвона',
      failedCallNoRansom: 'Невыкуп с недозвона',
    },
    callPP: 'Дозвон ПП',
    failCallPP: 'Недозвон ПП',
    status: 'Статус',
    count: 'Кол-во',
    chart: {
      approve: 'Апрув:',
      cancel: 'Отмена:',
      shipped: 'Отправлено:',
      awaitingRansom: 'Ожид.выдачи:',
      ransom: 'В дороге:',
      noRansom: 'Невыкуп:',
      total: 'Всего дозвон ПП:',
      totalFail: 'Всего недозвон ПП:',
    },
    filters: {
      offerGroups: 'Группа офферов',
      offer: 'Оффер',
      offerNiche: 'Ниша офферов',
      salesModel: 'Модель продаж',
      salesType: 'Тип продаж',
      userGroups: 'Группа пользователей',
      operators: 'Операторы',
      country: 'Страна',
      region: 'Область',
      tags: 'Теги',
      deliveryType: 'Способ доставки',
      deliveryService: 'Служба доставки',
    },
    gender: {
      male: 'Мужской',
      female: 'Женский',
    },
  },
  callStatistics: {
    title: 'Статистика по звонкам',
    select: {
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      operator: 'Оператор',
      country: 'Страна',
      operator_groups: 'Группа операторов',
      delivery_service: 'Служба доставки',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
      age: 'Возраст',
      gender: 'Пол',
      web: 'Веб',
    },
    filterDateBy: {
      auto: 'По автообзвону',
      manual: 'По ручному прозвону',
    },
    newReport: {
      totalCalls: 'Совершено звонков',
      avgTime: 'Средняя прод.',
      totalTime: 'Общая прод.',
    },
    total: 'Совершено звонков',
    noCall: 'Недозвонов',
    call: 'Дозвонов',
    quantity: 'Кол-во',
    averageDuration: 'Средняя прод.',
    totalDuration: 'Общая прод.',
    lessThirty: 'Менее 30 сек',
    lessTen: 'от 30 сек до 10 мин.',
    moreTen: 'Более 10 мин',
    filters: {
      groupOperators: 'Группы операторов',
      operators: 'Операторы',
      offerGroups: 'Группы офферов',
      offers: 'Офферы',
      niches: 'Ниши',
      salesTypes: 'Типы продаж',
      salesModels: 'Модели продаж',
      country: 'Страна',
      regions: 'Области',
      deliveryTypes: 'Типы доставки',
      deliveryServices: 'Службы доставки',
      webs: 'Вебы',
    },
  },
  callCenterPrice: {
    title: 'Стоимость КЦ',
    month: 'Месяц',
    country: 'Страна',
    currency: 'Валюта',
    salesModel: 'Модель продаж',
    salesType: 'Тип продаж',
    typeDefaultOffer: 'Тип осн. продукта оффера',
    price: 'Стоимость',
    offer: 'Оффер',
    dateDownload: 'Дата загрузки',
    operatorDownload: 'Загрузил',
    import: 'Импорт Cтоимость КЦ',
    addFile: 'Добавить файл',
    infoImport:
      'Скачайте шаблон для заполнения данных, заполните и загрузите.<br/>Загружаемый файл полностью перезапишет данные в системе.',
    importData: 'Импортировать',
    exportTable: 'Скачать таблицу для редактирования можно здесь',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    warningText: 'Загружаемый файл полностью перезапишет<br/>данные в системе.',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    filters: {
      salesModel: 'Модель продаж',
      country: 'Страна',
      warehouse: 'Склад',
      productType: 'Тип продукта (СРМ)',
    },
    field: {
      country: 'Страна',
      salesModel: 'Модель продаж',
      productType: 'Тип осн. продукта оффера',
      price: 'Стоимость',
      currency: 'Валюта',
      errors: 'Информация',
      offer: 'Оффер',
    },
  },
  deliveryTax: {
    title: 'Прочие комиссии',
    month: 'Месяц',
    country: 'Страна',
    currency: 'Валюта',
    deliveryService: 'Служба доставки',
    productType: 'Тип осн.продукта оффера',
    bid: 'Ставка',
    dateDownload: 'Дата загрузки',
    author: 'Загрузил',
    import: 'Импорт Прочие комиссии',
    addFile: 'Добавить файл',
    infoImport:
      'Скачайте шаблон для заполнения данных, заполните и загрузите.<br/>Загружаемый файл полностью перезапишет данные в системе.',
    importData: 'Импортировать ',
    exportTable: 'Скачать таблицу для редактирования можно здесь',
    preview: 'Предпросмотр',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    warningText: 'Загружаемый файл полностью перезапишет<br/>данные в системе.',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    field: {
      country: 'Страна',
      deliveryService: 'Служба доставки',
      delivery: 'Служба доставки',
      productType: 'Тип осн.продукта оффера',
      rate: 'Ставка',
      currency: 'Валюта',
      errors: 'Информация',
    },
  },
  logisticPrice: {
    title: 'Стоимость логистики',
    import: 'Импорт Стоимость логистики',
    month: 'Месяц',
    country: 'Страна',
    currency: 'Валюта',
    deliveryService: 'Служба доставки',
    directDeliveryCost: 'Стоимость прямой доставки',
    backDeliveryCost: 'Стоимость обратной доставки',
    createdAt: 'Дата загрузки',
    createdBy: 'Загрузил',
    addFile: 'Добавить файл',
    exportTable: 'Скачать таблицу для редактирования можно здесь',
    preview: 'Предпросмотр',
    importData: 'Импортировать',
    correctRows: 'Правильных строк',
    rowsWithErrors: 'Строк с ошибками',
    infoImport:
      'Скачайте шаблон для заполнения данных, заполните и загрузите.<br/>Загружаемый файл полностью перезапишет данные в системе.',
    warningText: 'Загружаемый файл полностью перезапишет<br/>данные в системе.',
    modalImportDesc:
      'Будут импортированы только те строки, которые не содержат ошибок.<br/>Хотите продолжить?',
    field: {
      country: 'Страна',
      deliveryService: 'Служба доставки',
      currency: 'Валюта',
      price: 'Стоимость прямой доставки',
      backPrice: 'Стоимость обратной доставки',
      errors: 'Информация',
    },
  },
  telephony: {
    title: 'Телефония',
    login: 'Логин в телефонию',
    password: 'Пароль в телефонию',
  },
  financialReport: {
    title: 'Финансовый отчет',
    select: {
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      operator_groups: 'Группа операторов',
      operator: 'Оператор',
      delivery_service: 'Служба доставки',
      delivery_type: 'Способ доставки',
      country: 'Страна',
      region: 'Регион',
      city: 'Нас.пункт',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
      age: 'Возраст',
      gender: 'Пол',
      web: 'Веб',
      landing: 'Лендинг',
      organization: 'Юр.лицо',
      product_type: 'Тип продукта (СРМ)',
      warehouse: 'Склад',
    },
    filterDateBy: {
      createdAt: 'По заходу',
      approve: 'По апруву',
      shipped: 'По отправке ',
      delivered: 'По выкупу',
    },
    total: 'Итого',
    countOrders: 'Кол-во заказов',
    countOrdersTotal: 'Всего',
    countOrdersApprove: 'Апрувы',
    paidLead: 'Опл. лид',
    send: 'В дороге',
    ransom: 'Доставлен',
    revenue: 'Выручка',
    toOrder: 'На заказ',
    costPrice: 'Себестоимость',
    percentIncome: '% от дохода',
    leadCosts: 'Расходы на лиды',
    onPayment: 'На опл.',
    onConfirmation: 'На подтв.',
    shippingCost: 'Расход на доставку',
    returnShippingCost: 'Расход на обратную доставку',
    ccExpenses: 'Расходы на КЦ',
    deliveryTax: 'Прочие комиссии',
    costsNoRansom: 'Расходы на невыкуп',
    expenses: 'Расходы',
    profit: 'Прибыль',
    filters: {
      offer: 'Оффер',
      offerGroups: 'Группа офферов',
      niche: 'Ниша',
      salesModel: 'Модель продаж',
      salesType: 'Тип продаж',
      operator: 'Оператор',
      userGroups: 'Группа пользователей',
      country: 'Страна',
      region: 'Область',
      city: 'Нас.пункт',
      tags: 'Тег',
      deliveryType: 'Способ доставки',
      deliveryService: 'Служба доставки',
      organizations: 'Юр.лица',
      productType: 'Тип продукта (СРМ)',
      productTypeOffer: 'Тип продукта (Оффер)',
      gender: 'Пол',
      warehouse: 'Склад',
      age: 'Возраст',
      withErrors: 'Ошибки в рассчете',
    },
    modal: {
      attention: 'Внимание',
      close: 'Закрыть',
      total: 'Всего',
      orders: 'заказов',
      noData: 'Отсутствуют данные для',
      ordersKC: 'заказов по стоимости КЦ',
      ordersLogistic: 'заказов по стоимости Логистики',
      ordersTax: 'заказов по комиссиям и прочим начислениям',
      ordersLead: 'заказов по стоимости лида.',
      ordersPrime: 'заказов по себестоимости заказа.',
    },
    infoTab: {
      information: 'Информация',
      error: 'Ошибка',
      dataIsAbsentFor:
        'Отсутствуют данные для {amount} заказов за отображаемый период. Для просмотра деталей нажмите на кнопку «Ошибки».',
    },
    otherTotal: 'Всего',
    withErrorsOnly: 'Только с ошибками',
    withoutErrorsOnly: 'Без ошибок',
    amountOfErrorsTotal: 'Кол-во заказов с ошибками: за текущий месяц - {month}, за год - {year}',
    errorNotFound: 'Ошибок не найдено',
  },
  financialReportOrders: {
    title: 'Заказы для фин. отчета',
    table: {
      id: 'ID',
      country: 'Страна',
      offer: 'Oффер',
      landing: 'Лендинг',
      web: 'Веб',
      income: 'Доход',
      costPrice: 'Себестоимость',
      perLead: 'За лид',
      delivery: 'Доставка',
      deliveryBack: 'Обр. дост.',
      onKC: 'На КЦ',
      tax: 'Прочие комиссии',
      profit: 'Прибыль',
      otherErrors: 'Другие ошибки',
    },
    info: 'Информация',
    orders: 'Заказ',
    currency: 'Валюта',
    id: 'Номер заказа',
    createdAt: 'Дата захода',
    country: 'Страна заказа',
    offer: 'Оффер',
    salesType: 'Тип продаж',
    salesModel: 'Модель продаж',
    status: 'Статус',
    source: 'Источник',
    web: 'Веб',
    productType: 'Тип продукта',
    productTypeCrm: 'Тип продукта CRM',
    deliveryService: 'Служба доставки',
    warehouse: 'Склад',
    ransom: 'Доход',
    hasRansom: 'Выкуп',
    ransomDate: 'Дата выкупа',
    ransomSum: 'Сумма чека',
    ransomRate: 'Курс конвертации',
    ransomTotal: 'Доход',
    lead: 'Цена за лид',
    hasLead: 'Оплата за лид',
    leadPrice: 'Сумма оплаты за лид',
    leadRate: 'Курс конвертации',
    leadTotal: 'Сумма',
    prime: 'Себестоимость',
    isShipped: 'Отправлено',
    noRansom: 'Невыкуп',
    canReturn: 'Возвратный',
    disbanded: 'Расформирован',
    shippedDate: 'Дата/время отправки',
    primeCost: 'Сумма себестоимости',
    primeRate: 'Курс конвертации',
    primeTotal: 'Сумма',
    cc: 'Стоимость КЦ',
    hasApprove: 'Апрув',
    approveDate: 'Дата/время апрува',
    callCenterPrice: 'Сумма стоимости КС',
    callCenterRate: 'Курс конвертации',
    callCenterTotal: 'Сумма',
    deliveryCoast: 'Стоимость доставки',
    isShippedA: 'Отправлено',
    shippedDateA: 'Дата/время отправки',
    shippedPrice: 'Сумма стоимости доставки',
    shippedRate: 'Курс конвертации',
    shippedTotal: 'Сумма',
    deliveryBack: 'Обратная доставка',
    noRansomA: 'Невыкуп',
    canReturnA: 'Возвратный',
    disbandedA: 'Расформирован',
    noRansomDate: 'Дата/время невыкупа',
    backPrice: 'Сумма обратной доставки',
    backRate: 'Курс конвертации',
    backTotal: 'Сумма',
    ndc: 'Прочие комиссии и сборы',
    tax: 'Процент комиссии',
    check: 'Чек заказа',
    checkRate: 'Курс конвертации',
    checkTotal: 'Чек',
    checkTax: 'Комиссии и сборы',
    profit: 'Прибыль',
    exportFile: 'Заказы для фин. отчета',
  },
  approveSpeed: {
    title: 'Скорость апрува',
    total: 'Всего',
    totalTable: 'Итого:',
    hours: 'Час',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    offer: 'Оффер',
    groupOffer: 'Группа офферов',
    offer_group: 'Группа офферов',
    offerNiches: 'Ниша офферов',
    offer_niche: 'Ниша офферов',
    countries: 'Страна',
    region: 'Область',
    operatorGroup: 'Группа операторов',
    user_group: 'Группа операторов',
    operator: 'Оператор',
    landing: 'Лендинг',
    web: 'Веб',
    deliveryService: 'Служба доставки',
    delivery_service: 'Служба доставки',
    salesModel: 'Модель продаж',
    sales_model: 'Модель продаж',
    salesType: 'Тип продаж',
    sales_type: 'Тип продаж',
    age: 'Возраст',
    gender: 'Пол',
    filterDateBy: {
      createdAt: 'По заходу',
      approve: 'По апруву',
      processing: 'По обработке',
    },
    periods: {
      hour: 'Часы',
      day: 'Дни',
      call: 'Звонки',
    },
    filters: {
      offers: 'Оффер',
      offerGroups: 'Группа офферов',
      niches: 'Ниши',
      webs: 'Вебы',
      landings: 'Лендинги',
      salesModel: 'Модели продаж',
      salesTypes: 'Тип продаж',
      operators: 'Операторы',
      userGroups: 'Группы пользователей',
      countries: 'Страны',
      regions: 'Области',
      tags: 'Теги',
      deliveryServices: 'Служба доставки',
    },
  },
  permission: {
    title: 'Контроль доступов',
    permissionTitle: 'Название доступа',
    count: 'Кол-во пользователей',
    viewPermission: 'Просмотр номера телефона',
    user: 'Пользователь',
    role: 'Должность',
    group: 'Группа',
    disableAccess: 'Отключение доступа',
    back: 'К списку',
  },
  dostavim: {
    title: 'Отчет почта-dostavim',
    filters: {
      day: 'День',
      month: 'Месяц',
      offer: 'Оффер',
      offer_group: 'Группа офферов',
      offer_niche: 'Ниша офферов',
      operator_groups: 'Группа операторов',
      operator: 'Оператор',
      country: 'Страна',
      region: 'Область',
      sales_model: 'Модель продаж',
      sales_type: 'Тип продаж',
    },
    shipmentsCD: 'Отправки с доступной КД',
    sentCD: 'Отправлено КД',
    mail: 'Почта',
    deviationTarget: 'Отклонение от таргета',
    statistics: 'Статистика',
    setTarget: 'Установить таргет',
    targetLabel: 'Таргет для отображения на графике, %',
    madeByEmail: 'Оформлено на почту',
    target: 'Таргет',
  },
  ages: {
    less15: 'менее 15',
    more90: 'более 90',
  },
  penalty: {
    title: 'Штрафы и премии операторов',
    operator: 'Оператор',
    operatorGroup: 'Группа оператора',
    penalty: 'Штраф',
    holds: 'Удержания',
    addCharges: 'Доп. начисления',
    bulkActions: 'Массовое действие',
    action: 'Действие',
    accrual: 'Доп. начисления',
    operators: 'Операторов',
    date: 'Дата действия',
    selectPenalty: 'Выбор штрафа',
    sum: 'Сумма',
    selectHold: 'Выбор удержания',
    selectProfit: 'Выбор начисления',
    success: 'Успешно',
    actionsSuccess: 'Действие было применено для',
    actionsSuccessPart: 'операторов',
    addPenalty: 'Добавить штраф',
    lastPenalty: 'Предыдущие штрафы',
    comment: 'Комментарий',
    datePenalty: 'Дата штрафа',
    addProfit: 'Добавить начисления',
    lastProfit: 'Предыдущие начисления',
    dateProfit: 'Дата начисления',
    profit: 'Начисление',
    addHold: 'Добавить удержания',
    hold: 'Удержание',
    dateHold: 'Дата удержания',
    lastHold: 'Предыдущие удержания',
    allModal: 'Начисления и удержания оператора',
    penalties: 'Штрафы',
    userGroups: 'Группы операторов',
    noData: 'Нет данных',
  },
  createPassword: {
    title: 'Создайте пароль',
  },
  addSecurityPassword: {
    more15min: 'Более 15 мин',
    more30min: 'Более 30 мин',
    more1hour: 'Более 1 часа',
    more2hour: 'Более 2 часов',
    more3hour: 'Более 3 часов',
    more5hour: 'Более 5 часов',
    more24hour: 'Более 24 часов',
    noLogout: 'Не разлогинивать',
    noLimits: 'Без ограничений',
    showViewPhoneFull: 'Полностью',
    showViewPhonePartially: 'Частично',
    noShowViewPhone: 'Не показывать',
    error: 'Ошибка',
  },
  metashipAccounts: {
    firstFormTitle: 'Основные',
    secondFormTitle: 'Магазины',
    tooltipInfo:
      'Узнать API токен и ключ аккаунта вы можете в личном кабинете Metaship, в меню "Настройки" - "Интеграция"',
    dataUpdated: 'Данные успешно синхронизированы',
    wrongParams: 'Неправильный API токен и/или Секретный ключ',
    table: {
      name: 'Название',
      description: 'Описание',
      metashipId: 'ID Metaship',
      shortMetashipId: 'Короткий ID Metaship',
    },
    form: {
      name: 'Название',
      token: 'API токен',
      description: 'Описание',
      key: 'Секретный ключ',
      synchronize: 'Синхронизировать',
    },
  },
  repeatedConfirm: {
    title: 'Повторные подтверждения',
    form: {
      ageFrom: 'Возраст от',
      ageTo: 'Возраст до',
      ageEmpty: 'Без возраста',
      timeFrom: 'Время апрува с',
      timeTo: 'Время апрува по',
    },
  },
  deliveryServiceCode: {
    RussianPost: 'RussianPost',
    TopDelivery: 'TopDelivery',
    Cdek: 'Cdek',
    Boxberry: 'Boxberry',
    Drhl: 'Drhl',
    Dpd: 'Dpd',
    Cse: 'Cse',
    FivePost: 'FivePost',
    PickPoint: 'PickPoint',
    YandexGo: 'YandexGo',
    YandexDelivery: 'YandexDelivery',
    Svyaznoy: 'Svyaznoy',
    DostavkaClub: 'DostavkaClub',
    DostavkaGuru: 'DostavkaGuru',
  },
  logWatchNumber: {
    title: 'Логи просмотра телефонов/заявок',
    userGroups: 'Группа пользователей',
    table: {
      date: 'Дата',
      user: 'Пользователь',
      numberOrder: 'Номер заявки',
      typeAction: 'Тип действия',
    },
    watch_number: 'Просмотр номера',
    open_order: 'Открытие заявки',
    order_sms: 'Просмотр номера',
    eventName: 'Тип действия',
    operator: 'Группа пользователей',
    update: 'Обновление',
    create: 'Создание',
  },
  logAccounts: {
    title: 'Логи аккаунтов ',
    table: {
      date: 'Дата',
      initiator: 'Инициатор',
      changeAccount: 'Измененный аккаунт',
      typeAction: 'Тип действия',
    },
    event: {
      update: 'Редактирование аккаунта',
      create: 'Cоздание аккаунта',
      user_update_access: 'Редактирование доступов аккаунта',
      user_update_password: 'Cмена пароля аккаунта',
      user_deactivation: 'Деактивация аккаунта',
      user_activation: 'Активация аккаунта',
    },
    numberPhone: 'Телефонные номера',
    editAccess: 'Редактирование доступов',
    eventName: 'Тип действия',
    users: 'Пользователи',
    includesSensitiveAccess: 'Bключает чувствительные доступы',
    dateAndTime: 'Дата и время',
    account: 'Аккаунт',
    update: 'Изменение',
    be: 'Было',
    became: 'Стало',
  },
  abNormal: {
    backToLogs: 'К логам',
    btn: 'Подозрительные',
    title: 'Подозрительные просмотры',
    table: {
      date: 'Дата',
      user: 'Пользователь',
      userType: 'Тип пользователя',
      typeAction: 'Тип действия',
      countView: 'Кол-во просмотров',
    },
    order: 'Просмотр заявки',
    phone: 'Просмотр телефона',
    operator: 'Оператор',
    header: 'Руководитель',
    userType: 'Тип пользователя',
    typeAction: 'Тип действия',
    viewOrder: 'Просмотры заявок',
    modal: {
      numOrder: 'Номер заявки',
      dateTime: 'Дата и время',
      operator: 'Ответственный оператор',
      country: 'Страна',
      operatorFirstApprove: 'Оператор первого апрува',
    },
  },
  userSchedule: {
    title: 'Нормы часов и графики работы операторов',
    titleCreate: 'Норма часов и график работы операторов',
    norms: 'Норма часов',
    startShift: 'Начало смены',
    endShift: 'Конец смены',
    norma: 'Норма',
    intervals: 'Интервалы',
    hours: 'часов',
    errorIntervals: 'Диапазоны не должны совпадать',
    errorNorms: 'Такая норма часов уже существует',
  },
  infoLogAccounts: {
    partially: 'Частично',
    phoneViewLimit: 'Макс. кол-во просмотров номеров в сутки',
    phoneViewMode: 'Показ полного номер телефона',
    isOperator: 'Оператор',
    workStartDate: 'Дата трудоустройства',
    email: 'Email',
    name: 'Имя',
    lastName: 'Фамилия',
    bonusCurrency: 'Валюта для расчета бонусов',
    grade: 'Грейд оператора',
    cardNumber: 'Номер карты',
    additionalCardNumber: 'Доп. карта для выплат',
    telephonyUsername: 'Логин в телефонию',
    telephonyPassword: 'Пароль в телефонию',
    groups: 'Группа',
    permissions: 'Права',
    full: 'Полностью',
    countries: 'Страна',
    languages: 'Язык',
    offers: 'Оффер',
  },
  operatorSchedule: {
    title: 'График работы операторов',
    name: 'Имя',
    group: 'Группа',
    grade: 'Грейд',
    normaHours: 'Норма часов',
    plan: 'План',
    fact: 'Факт',
    schedule: 'Нормы часов',
    grades: 'Грейды',
    markAsViewed: 'Отметить, как просмотренные',
    history: 'История',
    date: 'Дата и время',
    initiator: 'Инициатор',
    was: 'Было',
    became: 'Стало',
    noData: 'Данные отсутствуют',
  },
  ticketTypes: {
    ticketTypes: 'Типы тикетов',
    ticketTypesCreate: 'Тип тикета',
    ticketTypesEdit: 'Тип тикета',
    main: 'Основные',
    form: {
      performerSettings: 'Настройки исполнителя',
      deadlineSettings: 'Настройки дедлайна',
      name: 'Название',
      deadlineFormat: 'Формат дедлайна',
      autoPerformer: 'Заполнять исполнителя автоматически',
      autoPerformerSign: 'По признаку',
      autoDeadline: 'Заполнять дедлайн автоматически',
      autoDeadlineSign: 'По признаку',
      timeAfter: 'Время с момента присвоения статуса',
      timeAfterCreate: 'Время с момента создания тикета',
      timeAfterUnit: '',
      status: 'Статус',
    },
    select: {
      exact: 'Точная дата и время',
      timer: 'Обратный таймер',
      status_time: 'От времени назначения статуса',
      create_time: 'От времени создания тикета',
      first_approve: 'Первый апрув',
      last_approve: 'Последний апрув',
      callback: 'Коллбек',
      shipped: 'В дороге',
      awaiting_ransom: 'Ожидает выкуп',
      delivered: 'Выкуп',
      no_ransom: 'Возврат',
      trash: 'Треш',
      canceled: 'Отмена',
    },
    autoPerformerSign: {
      first_approve: 'Оператор первого апрува',
    },
    list: {
      name: 'Название',
      deadlineFormat: 'Формат дедлайна',
      autoDeadlineSign: 'Автозаполнение дедлайна',
      autoPerformerSign: 'Автозаполнение исполнителя',
    },
  },
  ccStatisticsDashboard: {
    ccStatisticsDashboard: 'Загрузка КЦ',
    amountOperatorsDays: 'Кол-во операторов по времени суток',
    amountOperatorsWeeks: 'Кол-во операторов по дням недели',
    amountOperatorsMonths: 'Кол-во операторов по дням месяца',
    planAmount: 'Плановое кол-во операторов',
    tableView: 'Табл.вид',
    chartView: 'Граф.вид',
    group: 'Группа',
    plan: 'План',
    groupBy: {
      day: 'По часам',
      week: 'По дням недели',
      month: 'По дням месяца',
    },
  },
  smsSettings: {
    title: 'Настройки SMS',
    general: 'Основные',
    additional: 'Расширенные',
    operatorSmsDefault: 'Оператор SMS по умолчанию',
    operatorSms: 'Оператор рекламных SMS',
    beeline: 'Билайн',
    titleCountry: 'Дополнительные настройки для стран',
    titleAdditional: 'Расширенные настройки оператора отправки',
    operatorSent: 'Оператор отправки',
    operatorClient: 'Оператор клиента',
  },
  smsChain: {
    titleNewMessageUnit: 'Название уведомления',
    titleEdit: 'Новая цепочка уведомлений',
    title: 'Цепочки уведомлений',
    table: {
      id: 'ID',
      name: 'Название',
      county: 'Страна',
      deliveryService: 'Служба доставки',
      rateClient: 'Рейтинг клиента',
      date: 'Дата создания',
      dateStart: 'Дата старта (апрув с)',
      activeABTest: 'Активный A/B тест',
      statusChain: 'Статус цепочки',
      actions: 'Действия',
    },
    tabs: {
      general: 'Основные',
      smsChain: 'Цепочка уведомлений',
      stat: 'Статистика',
      statAB: 'Статистика A/B тестов',
    },
    titleGeneral: 'Параметры фильтрации заявок для цепочки уведомлений',
    name: 'Название цепочки',
    rating: {
      good: 'Good',
      neutral: 'Neutral',
      bad: 'Bad',
      invalid: 'Invalid',
    },
    ratingTitle: 'Рейтинг checkclient',
    status: 'Остановить уведомления при статусах',
    statuses: {
      draft: 'Черновик',
      error: 'Ошибка создания заказа',
      pending: 'Ожидает подтверждения службы доставки',
      created: 'Создан',
      'wait-delivery': 'Добавлен в партию',
      intransit: 'Доставляется',
      'dispatched-to-a-courier': 'Передан курьеру',
      stored: 'В пункте самовывоза',
      delivered: 'Доставлен',
      'expected-return': 'Готовится к возврату',
      'return-arrived-warehouse': 'Возвращен на склад службы доставки',
      'return-completed': 'Возвращен в магазин',
      losted: 'Утерян',
      unknown: 'Статус уточняется',
      cancelled: 'Отменен',
    },
    service: 'Служба доставки',
    country: 'Страна',
    offers: 'Офферы',
    dateApprove: 'Заявки, заапрувленные с',
    dateApproveTo: 'Заявки, заапрувленные до',
    filter: {
      rating: 'Рейтинг клиента',
    },
    titleSmsChain: 'Базовая цепочка уведомлений',
    titleStat: 'Статистика',
    titleStatAB: 'Статистика A/B тестов',
    baseSmsChain: {
      firstTitle: 'SMS',
      firstSubtitle: 'Заказ в пункте выдачи',
      secondTitle: 'Робопрозвон',
      secondSubtitle: 'Заказ в пункте<br/> выдачи, заберите<br/> сегодня',
      threeTitle: 'SMS',
      threeSubtitle: 'Заказ в пункте<br/> выдачи, заберите<br/> сегодня',
      addChain: 'Добавить<br/> уведомление',
    },
    unit: 'Уведомление',
    nameUnit: 'Название',
    type: {
      sms: 'SMS',
      operatorCall: 'Прозвон оператором КЦ',
    },
    typeUnit: 'Тип уведомления',
    operatorUnit: 'Оператор SMS',
    statusUnit: 'Статус СД',
    templateUnit: 'Шаблон',
    timeAfterUnit: '',
    timeAfter: 'Время с момента получения статуса СД',
    timeAfterObj: {
      day: 'дней',
      hour: 'часов',
      minute: 'минут',
    },
    costUnit: 'Стоимость одного уведомления',
    textSms: 'Текст SMS',
    statusChain: 'Статус цепочки',
    activeStatusChain: 'Активная',
    deactiveStatusChain: 'Деактивированная',
  },
  twoFactorAuthSelect: {
    title: 'Двухфакторная аутентификация',
    enabled: 'Включена',
    disabled: 'Выключена',
  },
  autoCall: {
    title: 'Автообзвон',
    id: 'ID заявки',
    date: 'Дата заявки',
    web: 'Веб',
    status: 'Cтатус заявки',
    country: 'Cтрана',
    dateAddedQueue: ' Дата добавления в очередь',
    dateDeletedQueue: ' Дата удаления из очереди',
    dateLastCall: 'Дата последнего звонка',
    statusLastCall: 'Результат звонка',
    callResult: 'Результат звонка',
    statusOktell: 'Статус Oktell',
    countCalls: 'Кол-во звонков',
    applicationQueue: 'Заявка в очереди',
    filter: {
      country: 'Страна',
      statusCall: 'Статус дозвона',
      statusOrder: 'Статус заявки',
      offer: 'Оффер',
      niche: 'Ниша',
      offerGroup: 'Группа офферов',
      operatorGroup: 'Группа операторов',
      web: 'Веб',
    },
    restart: 'Перезапустить',
    removeQueue: 'Удалить из очереди',
  },
  sendSms: {
    title: 'Отправить SMS',
    textSms: 'Текст SMS',
    template: 'Шаблон',
    successMessage: 'SMS успешно отправлено',
    noTemplate: 'Без шаблона',
  },
  trackingModal: {
    title: 'Трекинг заказа №',
    noInfo: 'Информация о статусе доставки отсутствует.',
    status: {
      draft: 'Черновик',
    },
  },
  orderReportFileName: {
    shippedMoreThan20Days: 'Отправлены более 20 дней (1025_1)',
    shippedMoreThanDaysNoBarCode: 'Отправлено более 15 дней без ШК (1025_2)',
    approvedMoreThan14Day: 'Апрув более 14 дней для Курьер (1025_3)',
    approvedMoreThan10Day: 'Апрув более 10 дней для НЕ Курьер (1025_3.2)',
    awaitingRansomMore30Day: 'Ожидает выкуп более 30 дней Почта (1025_4)',
    awaitingRansomMore15Day: 'Ожидает выкуп более 15 дней кроме Почта  (1025_4.2)',
    summaryPointKln: 'Общий балл КЛН за',
    deliveryInterval: 'Интервалы доставки',
    orders: 'Заявки',
    changesPoint: 'Изменения точек доставки',
    lastStatus: 'Operator bonus',
  },
  dashboardMixin: {
    exportToSVG: 'Сохранить SVG',
    exportToPNG: 'Сохранить PNG',
    exportToCSV: 'Сохранить CSV',
    menu: 'Меню',
    selection: 'Выбор',
    selectionZoom: 'Выбор с увеличением',
    zoomIn: 'Увеличить',
    zoomOut: 'Уменьшить',
    pan: 'Перемещение',
    reset: 'Сбросить увеличение',
    month: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
    shortMonths: {
      january: 'Янв',
      february: 'Фев',
      march: 'Мар',
      april: 'Апр',
      may: 'Май',
      june: 'Июн',
      july: 'Июл',
      august: 'Авг',
      september: 'Сен',
      october: 'Окт',
      november: 'Ноя',
      december: 'Дек',
    },
    days: {
      sunday: 'Воскресенье',
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
    },
    shortDays: {
      sunday: 'Вс',
      monday: 'Пн',
      tuesday: 'Вт',
      wednesday: 'Ср',
      thursday: 'Чт',
      friday: 'Пт',
      saturday: 'Сб',
    },
  },
  salesStatTranslation: {
    cancelled: 'Отмененные',
    no_ransom: 'Возврат',
    hot: 'Горячие',
    repeated: 'Повторные',
    certificate: 'Сертификат',
    repeated_confirm: 'Повторные подтверждения',
  },
}
export default locale
